import {
  Component,
  OnInit,
  AfterViewInit,
  Inject
} from '@angular/core';
import { WINDOW } from '../service/window.service';
import { DeviceService } from '../service/device.service';
import { RentalService } from '../service/rental.service';
import { DataAnalyticsService } from '../service/data-analytics.service';
import { UrlResolverService } from '../service/url-resolver.service';
import { StorageService } from 'app/service/storage.service';
import { InteractionsService } from '../service/interactions.service';
import { LogService } from 'app/service/log.service';
import { CliffLogDetails } from 'app/model/cliff-log-details.model';
import { LogDetails } from 'app/model/log-details.model';

@Component({
  selector: 'app-rental-confirmation',
  templateUrl: './rental-confirmation.component.html',
  styleUrls: ['./rental-confirmation.component.css']
})
export class RentalConfirmationComponent implements OnInit, AfterViewInit {
  currentPage = 'rental-confirmation';
  isDeviceMobile: boolean;
  vendorType: string;

  rentalAssignmentRequestNumber: string;

  private cliffLog: CliffLogDetails;
  private logDetails: LogDetails;

  constructor(
    @Inject(WINDOW) private window: Window,
    private deviceService: DeviceService,
    private rentalService: RentalService,
    private dataAnalyticsService: DataAnalyticsService,
    private urlResolverService: UrlResolverService,
    private storageService: StorageService,
    private interactionsService: InteractionsService,
    private logService: LogService,
  ) {

  }

  ngOnInit() {
    this.isDeviceMobile = this.deviceService.isDeviceMobile(this.window.innerWidth, this.window.innerHeight);
    this.vendorType = this.rentalService.getVendorType();
    this.rentalAssignmentRequestNumber = JSON.parse(this.storageService.getSessionStorage('rentalAssignmentRequestNumber') || null);
  }

  ngAfterViewInit() {
    let screenId = '';
    if (this.vendorType === 'Hertz') {
      screenId = 'ra-confirmation-hertz';
      this.dataAnalyticsService.sendPageData(screenId);
      this.sendConfirmationLogs(screenId);
    } else {
      screenId = 'ra-confirmation-erac'
      this.dataAnalyticsService.sendPageData(screenId);
      this.sendConfirmationLogs(screenId);
    }
    this.interactionsService.sendInteractions('Rental Self Service One Touch complete', 'Auto Rental');
    // this.splunkLogService.log('info', `CURRENT_PAGE=${this.currentPage}`);
  }

  sendConfirmationLogs(screenId: string) {
    this.cliffLog = new CliffLogDetails();
    this.cliffLog.message = screenId;
    this.cliffLog.messageId = 'RA_CONFIRMATION_LOGS';
    this.cliffLog.claimId = this.urlResolverService.getClaimId();
    this.cliffLog.clientOrPartyId = this.urlResolverService.getClientId();
    this.logDetails = new LogDetails(); 
    this.logDetails.currentPage = this.currentPage;
    this.logDetails.authentication = this.urlResolverService.getUrlParams().authentication;
    this.logDetails.role = this.urlResolverService.getRole();
    this.cliffLog.logData = this.logDetails;
    this.logService.sendLog(this.cliffLog);
  }

  redirect() {
    window.open(this.urlResolverService.getHubRedirectUrl(), '_self');
  }
}
