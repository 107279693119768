import { NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { UrlResolverService } from '../service/url-resolver.service';
import { ServiceClientsService } from '../service/service-clients.service';
import { ChangeView } from '../model/change-view.model';
import { SearchResultsService } from '../service/search-results.service';
import { WindowReference } from '../model/window-reference.model';
import { LatLongModel } from 'app/model/latLong.model';
import { StorageService } from 'app/service/storage.service';
import { LogService } from 'app/service/log.service';
import { LogDetails } from 'app/model/log-details.model';
import { CliffLogDetails } from 'app/model/cliff-log-details.model';

export abstract class BaseComponent {

  protected abstract isViewStatic: boolean;
  protected abstract currentPage: string;
  protected externalClaimId: string;
  protected externalClientId: string;
  protected role: string;

  protected cliffLog: CliffLogDetails;
  protected logDetails: LogDetails;
  private encodedSearchTerm: string;

  constructor(
    protected __zone: NgZone,
    protected router: Router,
    protected urlResolver: UrlResolverService,
    protected clientService: ServiceClientsService,
    protected searchResultsService: SearchResultsService,
    protected windowRef: WindowReference,
    protected storageService: StorageService,
    protected logService: LogService) {
  }

  changetoResultView(changeView?: ChangeView) {
    if (changeView.shouldChange) {
      this.isViewStatic = true;
      // if no provider is found and is eligible for pca
      // this.saveToLocalStorage();
      if (changeView.destination === 'PCA') {
        this.encodeSearchTerm();
        this.toPCA();
      } else if (changeView.destination === 'estimateAssist') {
        this.encodeSearchTerm();
        this.__zone.run(() => {
          this.toEstimateOptions();
        });
      } else {
        this.__zone.run(() => {
          this.toSearchResult(changeView.latlng);
        });
      }
    }
  }

  private encodeSearchTerm() {
    let searchTermEncoding = encodeURI(this.searchResultsService.getSearchedCriteria().searchTerm);
    searchTermEncoding = searchTermEncoding.replace(/'/g, '%27');
    searchTermEncoding = searchTermEncoding.replace(/,/g, '%2C');
    this.encodedSearchTerm = searchTermEncoding;
  }

  private toEstimateOptions() {
    this.router.navigate(['estimateoptions'], { queryParams: this.urlResolver.getQueryParamsForUrl() });
  }

  private toPCA() {
    const photoEstimateEligibility = this.clientService.getPhotoEstimateEligibility();

    if (photoEstimateEligibility) {
      this.buildDefaultLog('info', 'eligible for virtual estimate');
      
      if (photoEstimateEligibility.eligible === true) {
        // New PE
        this.logDetails.navigatingTo = 'new photo estimate';
        this.router.navigate(['nonprogram']);
      } else {
        // Old PE
        this.logDetails.navigatingTo = 'PCA';
        this.windowRef.navigateTo(
          this.urlResolver.getPCAUrl(
            this.clientService.getVehicleNumber(),
            this.searchResultsService.getRequestPostalCode(), '',
            this.encodedSearchTerm
          )
        );
      }

      this.logEvent();
    }
  }

  private toSearchResult(latlng: LatLongModel) {
    this.buildDefaultLog('info', 'proceed to search results');
    this.logDetails.navigatingTo = 'search results & map';
    this.logEvent();
    this.router.navigate(['repairshops'], {
      queryParams: {
        claim: this.urlResolver.getClaimId(),
        client: this.urlResolver.getClientId(),
        role: this.role,
        lat: latlng.lat,
        lng: latlng.lng,
        term: this.urlResolver.getStringifiedProviderRequest(
          this.searchResultsService.getProviderRequest()
        ),
      },
    });
  }


  protected buildDefaultLog(logLevel: string, message: string) {
    this.cliffLog = new CliffLogDetails();
    this.logDetails = new LogDetails();
    this.cliffLog.logLevel = logLevel;
    this.cliffLog.message = message;
    this.logDetails.currentPage = this.currentPage;
    if (this.externalClientId) {
      this.cliffLog.clientOrPartyId = this.externalClientId;
    }
    if (this.role) {
      this.logDetails.role = this.role;
    }
  }

  protected logEvent() {
    this.cliffLog.logData = this.logDetails;
    this.logService.sendLog(this.cliffLog);
  }

}
