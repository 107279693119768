import {
  Component,
  OnInit,
  Inject,
  AfterViewInit
} from '@angular/core';
import { Router } from '@angular/router';
import { vehiclesTrigger, vendorTrigger, phoneTrigger } from './rental-vehicle-preference.component.animations';

// SERVICES
import { RepairExperienceService } from 'app/service/repair-experience.service';
import { RentalRatesInfoService } from 'app/service/rental-rates-info.service';
import { RentalService } from '../service/rental.service';
import { CoverageInfoService } from 'app/service/coverage-info.service';
import { WINDOW } from '../service/window.service';
import { DeviceService } from '../service/device.service';
import { StorageService } from 'app/service/storage.service';
import { DataAnalyticsService } from '../service/data-analytics.service';
import { UrlResolverService } from '../service/url-resolver.service';
import { ShopInfoService } from '../service/shop-info.service';
import { ServiceClientsService } from 'app/service/service-clients.service';
import { LogService } from 'app/service/log.service';

// MODELS & ENUMS
import { RentalAssignment } from 'app/model/rental-assignment.model';
import { ShopInfo } from 'app/model/shop-info.model';
import { Address } from 'app/model/address.model';
import { Phone } from 'app/model/phone.model';
import { CoverageInfo } from 'app/model/coverage-info.model';
import { CliffLogDetails } from 'app/model/cliff-log-details.model';
import { LogDetails } from 'app/model/log-details.model';
import { InteractionsService } from '../service/interactions.service';
import { DeliveryPreference } from 'app/enums/rental-delivery-preference';
import { VehicleClass, VehicleClassCode } from 'app/enums/rental-vehicle-class';

@Component({
  selector: 'app-rental-vehicle-preference',
  templateUrl: './rental-vehicle-preference.component.html',
  styleUrls: ['./rental-vehicle-preference.component.css'],
  animations: [vehiclesTrigger, vendorTrigger, phoneTrigger]
})
export class RentalVehiclePreferenceComponent implements OnInit, AfterViewInit {
  static readonly MAX_RENTAL_DAYS: number = 20;

  private cliffLog: CliffLogDetails;
  private logDetails: LogDetails;

  currentPage = 'rental-vehicle-preference';
  isDeviceMobile: boolean;
  coverageInfo: CoverageInfo;
  coverageValues: Map<String, String>;

  coverageDailyLimit: string;
  coverageLimit: string;
  coverageType: string;
  rentalCoveragePercent: string;
  rentalPolicyText: string;

  deliveryPreference: boolean;
  oneX: any;
  medallia: any;

  vehicleSelection: string;
  vehicleSelectionView: any;
  vendorSelectionView: any;
  phoneFieldView: any;
  vehicleClassCode: string;
  isRentalSubmitDisabled: boolean;
  retryCount: number;

  phone: string;
  isValidPhone: boolean;

  dailyCostIntermediateHertz: string;
  dailyCostEconomyHertz: string;
  daysUntilCoverageMaxIntermediateHertz: string;
  daysUntilCoverageMaxEconomyHertz: string;
  rentalEconomyRateHertz: string;
  rentalIntermediateRateHertz: string;

  dailyCostIntermediateEnterprise: string;
  dailyCostCompactEnterprise: string;
  daysUntilCoverageMaxIntermediateEnterprise: string;
  daysUntilCoverageMaxCompactEnterprise: string;
  rentalCompactRateEnterprise: string;
  rentalIntermediateRateEnterprise: string;

  isClaimStateVirginia: boolean;
  isDefaultStateMinnesota: boolean;
  isCommercialVehicle: boolean;

  economyRateHertz: number;
  intermediateRateHertz: number;
  compactRateEnterprise: number;
  intermediateRateEnterprise: number;
  displayDailyLimit: string;
  rentalAssignment: RentalAssignment;
  externalClaimId: string;
  externalParticipantId: string;
  externalClientId: string;
  shopInfo: ShopInfo;
  vendorType: string;

  constructor(
    @Inject(WINDOW) private window: Window,
    private deviceService: DeviceService,
    private rentalService: RentalService,
    private rentalRatesInfoService: RentalRatesInfoService,
    private coverageInfoService: CoverageInfoService,
    private storageService: StorageService,
    private dataAnalyticsService: DataAnalyticsService,
    private router: Router,
    private urlResolverService: UrlResolverService,
    private repairExperienceService: RepairExperienceService,
    private shopInfoService: ShopInfoService,
    private clientService: ServiceClientsService,
    private logService: LogService,
    private interactionsService: InteractionsService,
  ) {
    this.deliveryPreference = true;
    this.oneX = window['oneX'];
    this.medallia = window['KAMPYLE_ONSITE_SDK'];
    this.vehicleSelectionView = 'open';
    this.vendorSelectionView = 'closed';
    this.phoneFieldView = 'closed';
    this.isRentalSubmitDisabled = false;
  }

  ngOnInit() {
    this.isDeviceMobile = this.deviceService.isDeviceMobile(this.window.innerWidth, this.window.innerHeight);
    this.deliveryPreference = this.rentalService.getDeliveryPreference();
    this.oneX.Help.init();
    this.oneX.Icons.init();
    this.oneX.Modal.init();
    this.oneX.FloatingLabel.init();
    this.oneX.AutoFormat.init();
    this.isValidPhone = false;
    this.isRentalSubmitDisabled = true;

    this.shopInfo = this.shopInfoService.getShopInfo();
    this.externalClaimId = this.urlResolverService.getClaimId();
    this.externalParticipantId = this.clientService.getParticipantId();
    this.externalClientId = this.urlResolverService.getClientId();
    this.isCommercialVehicle = this.clientService.getCommercialVehicleIndicator();
    this.economyRateHertz = Number(this.rentalRatesInfoService.getEconomyRatePriceInclusiveHertz());
    this.intermediateRateHertz = Number(this.rentalRatesInfoService.getIntermediateRatePriceInclusiveHertz());
    this.compactRateEnterprise = Number(this.rentalRatesInfoService.getCompactRatePriceInclusiveEnterprise());
    this.intermediateRateEnterprise = Number(this.rentalRatesInfoService.getIntermediateRatePriceInclusiveEnterprise());
    this.setCoverageLimits();
    this.setClaimStateFlag();
    this.calculateCoverageInformation();
    this.setRentalPolicyText();
    if (this.deliveryPreference) {
      this.dataAnalyticsService.sendPageData('ra-delivery-vendor-preference');
    } else {
      this.dataAnalyticsService.sendPageData('ra-vendor-preference');
    }
    this.interactionsService.sendInteractions('Rental Self Service One Touch start', 'Auto Rental');
  }

  submitRentalAssignmentRequest() {
    this.isRentalSubmitDisabled = true;
    this.prepareRentalAssignmentRequest();
    this.increaseRetryCount();
    this.repairExperienceService
      .sendRentalAssignments(
        this.rentalAssignment
      )
      .subscribe({
        next: status => {
          if (status === '1') {
            this.cliffLog = new CliffLogDetails();
            this.cliffLog.clientOrPartyId = this.urlResolverService.getClientId();
            this.cliffLog.message = 'Rentalassignments - post success';
            this.logDetails = new LogDetails();
            this.logDetails.currentPage = this.currentPage;
            this.logDetails.httpRequest = 'rentalassignments';
            this.logDetails.httpResponse = '201';
            this.logDetails.role = this.urlResolverService.getRole();
            this.logDetails.authentication = this.urlResolverService.getUrlParams().authentication;
            this.logDetails.rentalVehicleClass = this.vehicleClassCode;
            if (this.vehicleClassCode === 'ENTERPRISEINTERMEDIATE' || this.vehicleClassCode === 'HERTZINTERMEDIATE') {
              this.logDetails.rentalVehicleClass = 'INTERMEDIATE';
            }
            this.logDetails.rentalVendor = this.vendorType;
            this.cliffLog.logData = this.logDetails;
            this.logService.sendLog(this.cliffLog);
            this.routeToRentalConfirmation();
            this.oneX.Modal.hideModal(this, 'rental-assignments-retry-modal');
          } else {
            if (this.isRentalAssignmentsRetryAvailable()) {
              this.routeToRentalErrorModal();
            } else {
              this.routeToErrorPage();
            }
          }
        },
        error: error => {
          if (error.status === 401) {
            this.cliffLog = new CliffLogDetails();
            this.logDetails = new LogDetails();
            this.cliffLog.message = 'Rentalassignments - token expired in rental assignment - get lite & retry';
            this.cliffLog.logData = this.logDetails;
            this.logService.sendLog(this.cliffLog);
          }
          if (this.isRentalAssignmentsRetryAvailable()) {
            this.cliffLog = new CliffLogDetails();
            this.cliffLog.message = 'Rentalassignments - post failed';
            this.cliffLog.clientOrPartyId = this.urlResolverService.getClientId();
            this.cliffLog.httpResponse = error.status;
            this.logDetails = new LogDetails();
            this.logDetails.currentPage = this.currentPage;
            this.logDetails.role = this.urlResolverService.getRole();
            this.logDetails.httpRequest = error.url;
            this.logDetails.httpResponse = `${error.status.toString()}`;
            this.logDetails.rentalVehicleClass = this.vehicleClassCode;
            if (this.vehicleClassCode === 'ENTERPRISEINTERMEDIATE' || this.vehicleClassCode === 'HERTZINTERMEDIATE') {
              this.logDetails.rentalVehicleClass = 'INTERMEDIATE';
            }
            this.logDetails.rentalVendor = this.vendorType;
            this.cliffLog.logData = this.logDetails;
            this.logService.sendLog(this.cliffLog);

            this.routeToRentalErrorModal();
          } else {
            this.cliffLog.logLevel = 'error';
            this.cliffLog.message = 'Rentalassignments - post failed - retry';
            this.cliffLog.clientOrPartyId = this.urlResolverService.getClientId();
            this.cliffLog.httpResponse = error.status;
            this.logDetails.currentPage = this.currentPage;
            this.logDetails.role = this.urlResolverService.getRole();
            this.logDetails.repairAssignmentCompleted = 'false';
            this.logDetails.httpRequest = error.url;
            this.logDetails.httpResponse = `${error.status.toString()}`;
            this.logDetails.rentalVehicleClass = this.vehicleClassCode;
            if (this.vehicleClassCode === 'ENTERPRISEINTERMEDIATE' || this.vehicleClassCode === 'HERTZINTERMEDIATE') {
              this.logDetails.rentalVehicleClass = 'INTERMEDIATE';
            }
            this.logDetails.rentalVendor = this.vendorType;
            this.cliffLog.logData = this.logDetails;
            this.logService.sendLog(this.cliffLog);

            this.routeToErrorPage();
          }
        },
      });
  }

  routeToRentalConfirmation() {
    this.rentalService.setVendorType(this.vendorType);
    this.interactionsService.setRentalLocationIdentifier(this.vendorType);
    this.router.navigate(['rentalconfirm']);
  }

  routeToRentalErrorModal() {
    this.isRentalSubmitDisabled = false;
    this.oneX.Modal.showModal(this, 'rental-assignments-retry-modal');
    if (this.vendorType === 'Hertz') {
      this.dataAnalyticsService.sendPageData('ra-submit-error-hertz');
    } else {
      this.dataAnalyticsService.sendPageData('ra-submit-error-erac');
    }
  }

  routeToErrorPage() {
    this.router.navigate(['errorpage']);
    this.oneX.Modal.hideModal(this, 'rental-assignments-retry-modal');
  }

  requestRentalButton() {
    if (this.isRentalAssignmentsRetryAvailable()) {
      this.submitRentalAssignmentRequest();
    } else {
      this.routeToErrorPage();
    }
  }

  isRentalAssignmentsRetryAvailable(): boolean {
    if (Number(this.storageService.getSessionStorage('rentalAssignmentsRetryCount')) < 3) {
      return true;
    }
    return false;
  }

  increaseRetryCount() {
    this.retryCount = Number(this.storageService.getSessionStorage('rentalAssignmentsRetryCount'));
    this.retryCount++;
    this.storageService.setSessionStorage('rentalAssignmentsRetryCount', this.retryCount.toString());
  }

  prepareRentalAssignmentRequest() {
    this.rentalAssignment = new RentalAssignment();
    this.rentalAssignment.claimId = this.externalClaimId;
    this.rentalAssignment.participantId = this.externalParticipantId;
    this.rentalAssignment.clientId = this.externalClientId;
    this.rentalAssignment.address = this.buildShopAddress();
    this.rentalAssignment.phoneNumber = this.buildShopPhone();
    this.rentalAssignment.deliveryShopName = this.shopInfo.shopName;
    this.rentalAssignment.deliveryShopPhone = this.shopInfo.phone;
    this.rentalAssignment.rentalDeliveryPreference = DeliveryPreference.DELIVERY;
    this.rentalAssignment.vendorType = this.vendorType;
    this.rentalAssignment.channelType = 'RT';
    if (this.vehicleClassCode !== 'NONE') {
      if (this.vehicleClassCode === 'ENTERPRISEINTERMEDIATE') {
        this.rentalAssignment.vehicleClassCode = 'INTERMEDIATE';
      } else if (this.vehicleClassCode === 'HERTZINTERMEDIATE') {
        this.rentalAssignment.vehicleClassCode = 'INTERMEDIATE';
      } else {
        this.rentalAssignment.vehicleClassCode = this.vehicleClassCode;
      }
    }
    if (this.coverageType === 'P') {
      this.rentalAssignment.rentalCoveragePercent = this.coverageDailyLimit;
    } else {
      this.rentalAssignment.dailyMaxCoverage = this.coverageDailyLimit;
    }
    this.rentalAssignment.maxCoverage = this.coverageLimit;
    this.rentalAssignment.totalDays = RentalVehiclePreferenceComponent.MAX_RENTAL_DAYS;
    // console.log('calling RentalAssignmentServic:\n' + JSON.stringify(this.rentalAssignment)); // Keep for local debugging
  }

  buildShopAddress() {
    if (this.shopInfo) {
      return new Address(this.shopInfo.street, this.shopInfo.city, this.shopInfo.state, this.shopInfo.zipCode);
    }
  }

  buildShopPhone() {
    if (this.phone) {
      const noHyphenPhoneNumber = this.phone.replace(/[^\d]/g, '');
      return new Phone(noHyphenPhoneNumber.substr(0, 3), noHyphenPhoneNumber.substr(3, 3), noHyphenPhoneNumber.substr(6, 4));
    }
  }

  ngAfterViewInit() {
    this.oneX.Icons.init();
    this.dataAnalyticsService.setScreenChannel('rentalselfservice');
    // medallia survey support
    this.medallia.updatePageView();

    setTimeout(() => {
      const pageHeight = document.getElementById('rental-vehicle-preference-container').offsetHeight + 'px';
      document.getElementById('rental-vehicle-preference-container').style.height = pageHeight;
    }, 10);
    // this.splunkLogService.log('info', `CURRENT_PAGE=${this.currentPage}`);
  }


  onVehicleSelection(selection: number) {
    this.vehicleSelection = VehicleClass.get(selection);
    this.vehicleClassCode = VehicleClassCode[selection];
    this.vehicleSelectionView = 'closed';
    if (selection === 0) {
      setTimeout(() => {
        this.vendorSelectionView = 'open';
      }, 300);
      if (this.deliveryPreference) {
        this.dataAnalyticsService.sendPageData('ra-delivery-vendor-preference');
      } else {
        this.dataAnalyticsService.sendPageData('ra-vendor-preference');
      }
    } else {
      if (selection === 2 || selection === 4) {
        this.vendorType = 'Hertz';
      } else if (selection === 1 || selection === 3) {
        this.vendorType = 'Enterprise';
      }
      setTimeout(() => {
        this.phoneFieldView = 'open';
      }, 300);
      if (this.vendorType === 'Enterprise') {
        this.dataAnalyticsService.sendPageData('ra-pref-contact-number-erac');
      } else {
        this.dataAnalyticsService.sendPageData('ra-pref-contact-number-hertz');
      }
    }
  }

  onVendorSelection($event: any) {
    const vendor = $event;
    if (vendor === 'enterprise') {
      this.vendorType = 'Enterprise';
    } if (vendor === 'hertz') {
      this.vendorType = 'Hertz';
    }
    this.vendorSelectionView = 'closed';
    setTimeout(() => {
      this.phoneFieldView = 'open';
    }, 300);
    if (this.vendorType === 'Enterprise') {
      this.dataAnalyticsService.sendPageData('ra-pref-contact-number-erac');
    } else {
      this.dataAnalyticsService.sendPageData('ra-pref-contact-number-hertz');
    }
  }

  editVehicleSelection() {
    this.vendorSelectionView = 'closed';
    this.phoneFieldView = 'closed';
    setTimeout(() => {
      this.vehicleSelectionView = 'open';
    }, 300);
    if (this.deliveryPreference) {
      this.dataAnalyticsService.sendPageData('ra-delivery-vendor-preference');
    } else {
      this.dataAnalyticsService.sendPageData('ra-vendor-preference');
    }
  }

  phoneValidator(event: any) {
    this.isValidPhone = /(^[(]{1}(?:([0-9]{3}[)]{1}[0-9]{3}-[0-9]{4}))?$|^[0-9]{3}-[0-9]{3}-[0-9]{4}$)/.test(event.target.value);
    if (this.isValidPhone && event.target.value.length >= 12) {
      this.phone = event.target.value;
      this.isRentalSubmitDisabled = false;
    } else {
      this.isRentalSubmitDisabled = true;
    }
  }

  calculateCoverageInformation() {
    this.storageService.setSessionStorage('rentalEconomyRateHertz', Math.ceil(this.economyRateHertz).toString());
    this.storageService.setSessionStorage('rentalIntermediateRateHertz', Math.ceil(this.intermediateRateHertz).toString());
    this.storageService.setSessionStorage('rentalCompactRateEnterprise', Math.ceil(this.compactRateEnterprise).toString());
    this.storageService.setSessionStorage('rentalIntermediateRateEnterprise', Math.ceil(this.intermediateRateEnterprise).toString());

    if (this.coverageType === 'P') {
      // VendorType - Hertz
      this.storageService.setSessionStorage('dailyCostIntermediateHertz', this.calculateDailyCostPercent(this.intermediateRateHertz).toString());
      this.storageService.setSessionStorage('dailyCostEconomyHertz', this.calculateDailyCostPercent(this.economyRateHertz).toString());
      this.storageService.setSessionStorage('daysUntilCoverageMaxIntermediateHertz', this.calculateDaysToCoverageMaxPercent(this.intermediateRateHertz).toString());
      this.storageService.setSessionStorage('daysUntilCoverageMaxEconomyHertz', this.calculateDaysToCoverageMaxPercent(this.economyRateHertz).toString());
      // VendorType - Enterprise
      this.storageService.setSessionStorage('dailyCostIntermediateEnterprise', this.calculateDailyCostPercent(this.intermediateRateEnterprise).toString());
      this.storageService.setSessionStorage('dailyCostCompactEnterprise', this.calculateDailyCostPercent(this.compactRateEnterprise).toString());
      this.storageService.setSessionStorage('daysUntilCoverageMaxIntermediateEnterprise', this.calculateDaysToCoverageMaxPercent(this.intermediateRateEnterprise).toString());
      this.storageService.setSessionStorage('daysUntilCoverageMaxCompactEnterprise', this.calculateDaysToCoverageMaxPercent(this.compactRateEnterprise).toString());
    } else {
      // VendorType - Hertz
      this.storageService.setSessionStorage('dailyCostIntermediateHertz', this.calculateDailyCost(this.intermediateRateHertz).toString());
      this.storageService.setSessionStorage('dailyCostEconomyHertz', this.calculateDailyCost(this.economyRateHertz).toString());
      this.storageService.setSessionStorage('daysUntilCoverageMaxIntermediateHertz', this.calculateDaysToCoverageMax(this.intermediateRateHertz).toString());
      this.storageService.setSessionStorage('daysUntilCoverageMaxEconomyHertz', this.calculateDaysToCoverageMax(this.economyRateHertz).toString());
      // VendorType - Enterprise
      this.storageService.setSessionStorage('dailyCostIntermediateEnterprise', this.calculateDailyCost(this.intermediateRateEnterprise).toString());
      this.storageService.setSessionStorage('dailyCostCompactEnterprise', this.calculateDailyCost(this.compactRateEnterprise).toString());
      this.storageService.setSessionStorage('daysUntilCoverageMaxIntermediateEnterprise', this.calculateDaysToCoverageMax(this.intermediateRateEnterprise).toString());
      this.storageService.setSessionStorage('daysUntilCoverageMaxCompactEnterprise', this.calculateDaysToCoverageMax(this.compactRateEnterprise).toString());
    }

    this.dailyCostIntermediateHertz = this.storageService.getSessionStorage('dailyCostIntermediateHertz');
    this.dailyCostEconomyHertz = this.storageService.getSessionStorage('dailyCostEconomyHertz');
    this.daysUntilCoverageMaxIntermediateHertz = this.storageService.getSessionStorage('daysUntilCoverageMaxIntermediateHertz');
    this.daysUntilCoverageMaxEconomyHertz = this.storageService.getSessionStorage('daysUntilCoverageMaxEconomyHertz');
    this.rentalEconomyRateHertz = this.storageService.getSessionStorage('rentalEconomyRateHertz');
    this.rentalIntermediateRateHertz = this.storageService.getSessionStorage('rentalIntermediateRateHertz');

    this.dailyCostIntermediateEnterprise = this.storageService.getSessionStorage('dailyCostIntermediateEnterprise');
    this.dailyCostCompactEnterprise = this.storageService.getSessionStorage('dailyCostCompactEnterprise');
    this.daysUntilCoverageMaxIntermediateEnterprise = this.storageService.getSessionStorage('daysUntilCoverageMaxIntermediateEnterprise');
    this.daysUntilCoverageMaxCompactEnterprise = this.storageService.getSessionStorage('daysUntilCoverageMaxCompactEnterprise');
    this.rentalCompactRateEnterprise = this.storageService.getSessionStorage('rentalCompactRateEnterprise');
    this.rentalIntermediateRateEnterprise = this.storageService.getSessionStorage('rentalIntermediateRateEnterprise');
  }

  setCoverageLimits() {
    this.coverageInfo = this.coverageInfoService.getCoverageInfo();
    this.coverageValues = this.coverageInfo.coverageValues;
    this.coverageDailyLimit = this.coverageValues['DailyLimit'];
    this.coverageLimit = this.coverageValues['Limit'];
    this.coverageType = this.coverageValues['Type'];

    this.storageService.setSessionStorage('coverageDailyLimit', this.coverageDailyLimit);
    this.storageService.setSessionStorage('coverageLimit', this.coverageLimit);
    this.displayDailyLimit = (this.coverageType === 'A') ? '$' + this.coverageDailyLimit : this.coverageDailyLimit + '%';
  }

  setRentalPolicyText() {
    if (this.isClaimStateVirginia && !this.isCommercialVehicle) {
      this.rentalPolicyText = 'Your policy covers up to $' + this.coverageLimit + ' per claim.';
    } else if (this.isClaimStateVirginia && this.isCommercialVehicle) {
      this.rentalPolicyText = 'Your policy covers up to $900 of your rental and/or commercial transportation costs per claim.';
    } else if (!this.isClaimStateVirginia && this.coverageType === 'A' && !this.isCommercialVehicle) {
      this.rentalPolicyText = 'Your policy covers up to ' + this.displayDailyLimit + ' per day, up to $' + this.coverageLimit + ' per claim.';
    } else if (!this.isClaimStateVirginia && this.coverageType === 'A' && this.isCommercialVehicle) {
      this.rentalPolicyText = 'Your policy covers ' + this.displayDailyLimit + ' of your rental and/or commercial transportation costs up to $' + this.coverageLimit + ' per claim.';
    } else if (!this.isClaimStateVirginia && this.coverageType === 'P' && !this.isCommercialVehicle) {
      this.rentalPolicyText = 'Your policy covers ' + this.displayDailyLimit + ' per day, up to $' + this.coverageLimit + ' per claim.';
    } else if (!this.isClaimStateVirginia && this.coverageType === 'P' && this.isCommercialVehicle) {
      this.rentalPolicyText = 'Your policy covers ' + this.displayDailyLimit + ' of your rental and/or commercial transportation costs up to $' + this.coverageLimit + ' per claim.';
    } else {
      this.rentalPolicyText = 'Your rental and/or commercial transportation coverage will apply accordingly.';
    }
  }

  calculateDailyCost(rentalRate: number): number {
    let dailyCost = 0;
    if (this.isClaimStateVirginia) {
      dailyCost = (rentalRate - Number(this.coverageLimit));
    } else {
      dailyCost = (rentalRate - Number(this.coverageDailyLimit));
    }
    if (dailyCost < 0) { dailyCost = 0; }
    return Math.ceil(dailyCost);
  }

  calculateDailyCostPercent(rentalRate: number): number {
    let dailyCost = (rentalRate * (100 - Number(this.coverageDailyLimit)) / 100);
    if (dailyCost < 0) { dailyCost = 0; }
    return Math.ceil(dailyCost);
  }

  calculateDaysToCoverageMax(rentalRate: number): number {
    if (rentalRate <= Number(this.coverageDailyLimit) || this.isClaimStateVirginia) {
      return Math.floor(Number(this.coverageLimit) / rentalRate);
    } else {
      return Math.floor(Number(this.coverageLimit) / Number(this.coverageDailyLimit));
    }
  }

  calculateDaysToCoverageMaxPercent(rentalRate: number): number {
    return Math.floor(Number(this.coverageLimit) / (rentalRate * (Number(this.coverageDailyLimit) / 100)));
  }

  redirect() {
    window.open(this.urlResolverService.getHubRedirectUrl(), '_self');
  }

  setClaimStateFlag() {
    this.isClaimStateVirginia = (Number(this.coverageDailyLimit) === 0 ? true : false);
    this.isDefaultStateMinnesota = this.clientService.getRentalCompliance();
  }

  isRepeatRetry() {
    return Number(this.storageService.getSessionStorage('rentalAssignmentsRetryCount')) > 1;
  }

}
