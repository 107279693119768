import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { PlacesDetails } from '../model/places-details.model';
import { NgZone } from '@angular/core';
import { Shop } from '../model/shop.model';
import { ShopsConstants } from '../constant/shops-constants';

const options = {
  componentRestrictions: { country: 'us' },
  fields: ['address_components', 'formatted_address', 'geometry.location', 'types']
};

@Injectable()
export class GoogleSearchService {
  subscription: any;
  private searchElement: HTMLInputElement;
  private autocompleteSubject = new Subject();
  counter = 0;
  constructor(private __zone: NgZone) { }

  public bindGoogleSearch(elm: HTMLInputElement) {
    this.setInputElement(elm);
    this.setAutocomplete(elm, this.autocompleteSubject);
  }

  setAutocomplete(elm: HTMLInputElement, autocompleteSubject) {
    const autocomplete = new google.maps.places.Autocomplete(elm, options);
    autocomplete.addListener('place_changed', function () {
      autocompleteSubject.next(autocomplete.getPlace());
    });
    return autocomplete;
  }

  getAutocompleteSubject() {
    return this.autocompleteSubject;
  }

  public performSearch() {
    return new Observable(observer => {
      try {
        const geocoder = new google.maps.Geocoder();
        const address = this.searchElement.value;
        geocoder.geocode({ address }, (results, status) => {
          if (status === google.maps.GeocoderStatus.OK) {
            // console.log(results[0]);
            observer.next(results[0]);
            observer.complete();
          } else {
            // console.error('Error - ', results, ' & Status - ', status);
            if (status === google.maps.GeocoderStatus.ZERO_RESULTS) {
              observer.error('Address not found!');
              // console.log('no data found');
            } else {
              // observer.error(status);
              observer.error('No data found!');
            }
            observer.complete();
          }
        });
      } catch (error) {
        observer.error('error getGeocoding' + error);
        observer.complete();
      }
    });
  }

  public setInputElement(elm: HTMLInputElement) {
    this.searchElement = elm;
  }

  // public getSearchTerm(): string {
  //     return this.searchElement ? this.searchElement.value : '';
  // }

  getplaceId(shop: Shop, index: number) {
    const searchShop = shop.name + ' ' + shop.address + ' ' + shop.city + ' ' + shop.state;
    this.subscription = this.getPlace(searchShop, index, shop).subscribe(
      result => {
        this.__zone.run(() => {
          // console.log('looking up ' + searchShop + 'got ' + result);
          shop.placeDetails = this.getPlacesDetails(result);
        });
      },
      error => {
        console.log('error in finding geocode ' + error);
      }
    );
    return shop;
  }

  getPlace(address: string, cnt: number, shop: Shop) {
    let shopFound = false;
    let current_delay = 1000;
    const max_delay = 36000;
    const geocoder = new google.maps.Geocoder();
    return new Observable(observer => {
      try {
        const findGeocode = function () {
          geocoder.geocode({ address: address }, function (results, status) {
            if (status === google.maps.GeocoderStatus.OK) {
              for (let i = 0; i < results.length; i++) {
                const resultType = results[i].types[0];
                if (resultType.includes('car') || resultType.includes('establishment')) {
                  observer.next(results[i].place_id);
                  observer.complete();
                  shopFound = true;
                }
              }
              // If proper place_id not found, check against list of MSO (Multi Service Operator) shops and
              // updates shop name and runs geocoding search again
              if (!shopFound) {
                ShopsConstants.shop_names.forEach((elm) => {
                  if (shop.name.includes(elm.tparName)) {
                    address = elm.googleName + ' ' + shop.address + ' ' + shop.city;
                    shopFound = true;
                    findGeocode();
                  }
                });
              }
            } else {
              // Re run Geocode request in case of OVER_QUERY_LIMIT after 5 sec delay.
              console.log('getPlace Error: ', status);
              if (current_delay < max_delay) {
                setTimeout(function () {
                  findGeocode();
                }, current_delay);
                current_delay *= 2;
              } else {
                observer.complete();
              }
            }
          });
        };
        setTimeout(
          () => {
            findGeocode();
          },
          cnt < 9 ? 0 : 1000 * cnt
        );
      } catch (error) {
        observer.error('error getting Place Id' + error);
        observer.complete();
      }
    });
  }

  getPlacesDetails(placeId) {
    const placesDetailsObj = new PlacesDetails();
    const map = new google.maps.Map(document.createElement('div'));
    const service = new google.maps.places.PlacesService(map);
    const today = this.getDate();
    const self = this;
    var request = {
      placeId: placeId,
      fields: ['name','place_id', 'opening_hours', 'rating', 'user_ratings_total', 'website', 'photos'],
    };
    service.getDetails(
      request,
      function (place, status) {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          placesDetailsObj.placeId = placeId;
          placesDetailsObj.placeName = place.name;
          placesDetailsObj.websiteUrl = place.website;
          placesDetailsObj.website = self.getWebsite(place.website);
          placesDetailsObj.weekday_text = place.opening_hours
            ? place.opening_hours.weekday_text
            : [];
          placesDetailsObj.rating = place.rating * 20;
          placesDetailsObj.user_ratings_total = place['user_ratings_total'];

          if (today.getDay() === 0) {
            placesDetailsObj.dayIndexToday = 6;
          } else {
            placesDetailsObj.dayIndexToday = today.getDay() - 1;;
          }

          if ( place.photos ) {
            for (let i = 0; i < place.photos.length; i++) {
              if (place.photos[i].html_attributions[0].includes(placesDetailsObj.placeName.replace('&', '&amp;').replace('\'', '&#39;'))) {
                placesDetailsObj.photo = place.photos[i].getUrl();
                break;
              }
            }
          }

          for (let l = 0; l < placesDetailsObj.weekday_text.length; l++) {
            const text = placesDetailsObj.weekday_text[l];
            const day = text.substring(0, 2);
            let timeDetails = text.substring(
              text.indexOf(':') + 1,
              text.length
            );
            if (timeDetails.length > 19) {
              let firstTimes = timeDetails.substring(
                0,
                timeDetails.indexOf(',')
              );
              let secondTimes = timeDetails.substring(
                timeDetails.indexOf(',') + 2
              );
              firstTimes = self.addAmPm(firstTimes);
              secondTimes = self.addAmPm(secondTimes);
              timeDetails = firstTimes + ', ' + secondTimes;
            } else {
              timeDetails = self.addAmPm(timeDetails);
            }
            placesDetailsObj.weekday_text[l] = timeDetails;
          }

          let todayHours = '';

          todayHours = placesDetailsObj.weekday_text[placesDetailsObj.dayIndexToday];

          if (todayHours) {
            // does google actually have the shop hours?
            if (todayHours.trim() === 'Closed') {
              // shop is closed
              placesDetailsObj.open_now_alt = placesDetailsObj.open_now = 'Closed today';
            } else {
              // shop is open _sometime_ today
              self.setOpenHoursText(place, today, placesDetailsObj);
            }
          } else {
            // google does not have shop hours, set to undefined so the hours are not displayed
            placesDetailsObj.open_status = undefined;
            placesDetailsObj.open_now = undefined;
            placesDetailsObj.open_now_alt = undefined;
          }
        } else {
          console.log('Places Detail Error - ' + status);
        }
      }
    );
    return placesDetailsObj;
  }

  addAmPm(timeDetails) {
    let reversePeriod = 'AM';
    const timePeriod = timeDetails.substring(timeDetails.length - 2);
    if (timePeriod === reversePeriod) {
      reversePeriod = 'PM';
    }
    if (
      timeDetails.indexOf('Closed') < 0 &&
      timeDetails.indexOf(reversePeriod) < 0
    ) {
      if (timeDetails.indexOf(' ') === 0) {
        timeDetails = timeDetails.substring(1);
      }
      const spaceIndex = timeDetails.indexOf(' ');
      const time1 = timeDetails.substring(0, spaceIndex);
      const time2 = timeDetails.substring(spaceIndex);
      return time1 + ' ' + timePeriod + time2;
    }
    return timeDetails;
  }

  setOpenHoursText(place, today, placesDetailsObj) {
    const count = this.getTodayCount(today, placesDetailsObj);
    const TODAY = place.opening_hours.periods[today.getDay() - count];

    const openHour24 = TODAY.open.hours;
    const closeHour24 = TODAY.close.hours;

    const openMin = TODAY.open.minutes;
    const closeMin = TODAY.close.minutes;

    let openHour12: number;
    let closeHour12: number;

    const openAmPm = TODAY.open.hours < 12 ? 'AM' : 'PM';
    const closeAmPm = TODAY.close.hours < 12 ? 'AM' : 'PM';

    const USERDATE = new Date();
    const USERHOUR = USERDATE.getHours();
    const USERMINUTE = USERDATE.getMinutes();

    // convert military time to AM/PM
    openHour12 = this.convertTime(openHour24);
    closeHour12 = this.convertTime(closeHour24);

    if (USERHOUR <= openHour24) {
      if (USERHOUR === openHour24) {
        if (USERMINUTE < openMin) {
          placesDetailsObj.open_status = 'Closed';
          placesDetailsObj.open_now_alt = placesDetailsObj.open_now = this.formatOpenHoursSummary('Opens at ', openHour12, openMin, openAmPm);
        } else {
          placesDetailsObj.open_status = 'Open';
          placesDetailsObj.open_now = this.formatOpenHoursSummary('Open until ', closeHour12, closeMin, closeAmPm);
          placesDetailsObj.open_now_alt = this.formatOpenHoursSummary('Closes at ', closeHour12, closeMin, closeAmPm);
        }
      } else {
        placesDetailsObj.open_status = 'Closed';
        placesDetailsObj.open_now_alt = placesDetailsObj.open_now = this.formatOpenHoursSummary('Opens at ', openHour12, openMin, openAmPm);
      }
    } else if (USERHOUR >= closeHour24) {
      if (USERHOUR === closeHour24) {
        if (USERMINUTE >= closeMin) {
          placesDetailsObj.open_status = 'Closed';
          placesDetailsObj.open_now = this.formatOpenHoursSummary('Closed at ', closeHour12, closeMin, closeAmPm);
          placesDetailsObj.open_now_alt = this.formatOpenHoursSummary('Opens at ', openHour12, openMin, openAmPm);
        } else {
          placesDetailsObj.open_status = 'Open';
          placesDetailsObj.open_now = this.formatOpenHoursSummary('Open until ', closeHour12, closeMin, closeAmPm);
          placesDetailsObj.open_now_alt = this.formatOpenHoursSummary('Closes at ', closeHour12, closeMin, closeAmPm);
        }
      } else {
        placesDetailsObj.open_status = 'Closed';
        placesDetailsObj.open_now = this.formatOpenHoursSummary('Closed at ', closeHour12, closeMin, closeAmPm);
        placesDetailsObj.open_now_alt = this.formatOpenHoursSummary('Opens at ', openHour12, openMin, openAmPm);
      }
    } else {
      placesDetailsObj.open_status = 'Open';
      placesDetailsObj.open_now = this.formatOpenHoursSummary('Open until ', closeHour12, closeMin, closeAmPm);
      placesDetailsObj.open_now_alt = this.formatOpenHoursSummary('Closes at ', closeHour12, closeMin, closeAmPm);
    }
  }

  convertTime(hour24: any) {
    if (hour24 > 0 && hour24 <= 12) {
      return hour24;
    } else {
      return hour24 - 12;
    }
  }

  getTodayCount(today, placesDetailsObj) {
    let count = 0;
    for (let i = 0; i < today.getDay(); i++) {
      if (i === 0 && placesDetailsObj.weekday_text[6].trim() === 'Closed') {
        count++;
      } else if (
        i !== 0 &&
        placesDetailsObj.weekday_text[i - 1].trim() === 'Closed'
      ) {
        count++;
      }
    }
    return count;
  }

  getDate() {
    return new Date();
  }

  formatOpenHoursSummary(openStatus, hour, min, amPm) {
    return `${openStatus}${hour}:${(min === 0 ? '00' : min)} ${amPm}`;
  }
  
  getWebsite(url) {
    let website;

    if (url) {
      try {
        website = (new URL(url)).hostname;
      } catch (error) {
        website = 'Website';
      }
    }

    return website;
  }
}
