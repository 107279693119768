import {
  Component,
  OnInit,
  AfterViewInit,
  OnDestroy,
  NgZone,
  HostListener,
  Inject,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ReplaySubject, BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BaseComponent } from 'app/base/base.component';

// SERVICES
import { ServiceClientsService } from '../service/service-clients.service';
import { SearchResultsService } from '../service/search-results.service';
import { ShopInfoService } from '../service/shop-info.service';
import { DataAnalyticsService } from '../service/data-analytics.service';
import { UrlResolverService } from '../service/url-resolver.service';
import { StorageService } from 'app/service/storage.service';
import { LogService } from 'app/service/log.service';
import { WINDOW } from '../service/window.service';
import { InteractionsService } from '../service/interactions.service';

// MODELS
import { SecondaryAssignment } from 'app/model/secondary-assignment.model';
import { LogDetails } from 'app/model/log-details.model';
import { CliffLogDetails } from 'app/model/cliff-log-details.model';
import { WindowReference } from '../model/window-reference.model';
import { Maintenance } from '../model/maintenance.model';


@Component({
  selector: 'app-select-a-shop',
  templateUrl: './select-a-shop.component.html',
  styleUrls: ['./select-a-shop.component.css'],
})
export class SelectAShopComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
  externalClaimId: string;
  externalClientId: string;
  participantId: string;
  role: string;
  isViewStatic: boolean;
  currentPage = 'ra-select-a-shop';
  maintenance$: ReplaySubject<Maintenance>;
  complianceVerbiage$: Observable<string>;
  currentShop$: Observable<SecondaryAssignment>;
  title$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  title3$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  adspLanguage$: Observable<string>;
  complianceStateCode: string;
  title = 'Get an estimate and repair with an';
  title1 = 'Get an ';
  title2 = ' and repair with an';
  title3 = 'in-network Select Service shop.';
  medallia: any;
  oneX: any;
  private ngUnsubscribe = new Subject();

  constructor(
    @Inject(WINDOW) private window: Window,
    __zone: NgZone,
    router: Router,
    private route: ActivatedRoute,
    urlResolver: UrlResolverService,
    logService: LogService,
    private dataAnalyticsService: DataAnalyticsService,
    clientService: ServiceClientsService,
    searchResultsService: SearchResultsService,
    windowRef: WindowReference,
    storageService: StorageService,
    private shopInfoService: ShopInfoService, // used in html
    private deviceService: DeviceDetectorService,
    private interactionsService: InteractionsService,
  ) {
    super(
      __zone,
      router,
      urlResolver,
      clientService,
      searchResultsService,
      windowRef,
      storageService,
      logService
    );
    this.oneX = window['oneX'];

  }

  ngOnInit() {
    this.cliffLog = new CliffLogDetails();
    this.logDetails = new LogDetails();
    this.urlResolver.populateQueryParams(this.route);
    this.externalClaimId = this.urlResolver.getClaimId();
    this.externalClientId = this.urlResolver.getClientId();
    this.participantId = this.urlResolver.getParticipantId();
    this.role = this.urlResolver.getRole();
    this.initiate();

    this.maintenance$ = this.clientService.callToMaintenanceService();
    this.complianceVerbiage$ = this.clientService.getComplianceVerbiage();
    this.currentShop$ = this.clientService.getCurrentShopDetail();
    this.adspLanguage$ = this.clientService.getADSPLanguage();

    if (!this.externalClaimId) {
      this.title$.next(this.title);
      this.title3$.next(this.title3);
    }

    this.complianceVerbiage$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      response => {
        this.title$.next(this.title1 + response + this.title2);
        this.title3$.next(this.title3);
      },
      error => {
        this.title$.next(this.title);
        this.title3$.next(this.title3);
      }
    );

    this.oneX.ExpansionPanel.init();
    this.oneX.Drawer.init();
  }

  private initiate() {
    this.buildDefaultLog('info', 'Started RA');
    if (!this.externalClaimId) {
      this.logDetails.searchOnly = 'true';
    }
    this.logDeviceDetails();
    this.cliffLog.entryPoint = this.currentPage;
    this.cliffLog.logData = this.logDetails;
    this.logService.sendLog(this.cliffLog);

    if (this.externalClaimId && this.externalClientId) {
      this.clientService.initClientServices(
        this.externalClaimId,
        this.externalClientId,
        this.participantId
      );
    }
  }

  ngAfterViewInit() {
    if (!this.externalClaimId) {
      this.dataAnalyticsService.sendPageData('landing');
      this.interactionsService.sendInteractions('Repair Assistant start', 'Auto Repair');
    }

    if (this.clientService.assignmentCount) {
      this.dataAnalyticsService.sendPageData(this.dataAnalyticsService.setPageDataWithCount('landing', this.clientService.assignmentCount));
      this.interactionsService.sendInteractions('Repair Assistant start', 'Auto Repair');
    } else if (this.clientService.assignmentCount === 0) {
      this.dataAnalyticsService.sendPageData('landing');
      this.interactionsService.sendInteractions('Repair Assistant start', 'Auto Repair');
    } else {
      this.clientService.getAssignmentCount().pipe(takeUntil(this.ngUnsubscribe)).subscribe({
        next: res => {
          this.dataAnalyticsService.sendPageData(this.dataAnalyticsService.setPageDataWithCount('landing', res));
          this.interactionsService.sendInteractions('Repair Assistant start', 'Auto Repair');
        },
      });
    }
    // medallia survey support
    this.clientService.getClaimNumber().subscribe({
      next: response => {
        this.dataAnalyticsService.setClaimNumber(response);
      },
    });
    setTimeout(() => {
      if ((this.medallia = window['KAMPYLE_ONSITE_SDK'])) {
        this.medallia.updatePageView();
      } else {
        this.buildDefaultLog(
          'info',
          'medallia script failed to load within time limit'
        );
        this.logEvent();
      }
    }, 5000);
  }

  initializeContextualHelp() {
    window[`oneX`][`Help`].init();
  }

  private logDeviceDetails() {
    this.logDetails.devicePlatform = this.getDevicePlatform();
    this.logDetails.browser = this.deviceService.browser;
    this.logDetails.browserVersion = this.deviceService.browser_version;
    this.logDetails.os = this.deviceService.os;
    this.logDetails.osVersion = this.deviceService.os_version;
  }

  private getDevicePlatform() {
    if (this.deviceService.isDesktop) {
      this.interactionsService.setDeviceTypeName('DESK');
      return 'desktop';
    } else if (this.deviceService.isTablet) {
      this.interactionsService.setDeviceTypeName('TABL');
      return 'tablet';
    } else {
      this.interactionsService.setDeviceTypeName('MOBL');
      return 'mobile';
    }
  }

  mainTop() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  scrollBottom() {
    setTimeout(() => {
      const elem: HTMLElement = document.getElementById('adsp');
      elem.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }, 1);
  }

  scrollToHelpTopic(element: string) {
    setTimeout(() => {
      const elem: HTMLElement = document.getElementById(element);
      elem.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }, 1);
  }

  onDesktop() {
    if (document.documentElement.clientWidth > 576) {
      return true;
    }
  }

  getScrolled() {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const scrolled = (winScroll / height) * 100;
    return scrolled;
  }

  changeFont(scrolled: number) {
    if (scrolled >= 3) {
      document.getElementById('myPosition').className =
        'vertical-indicator-container-fixed show';
    } else {
      document.getElementById('myPosition').className =
        'vertical-indicator-container-fixed hide';
    }
    if (scrolled > 99) {
      document.getElementById('myPosition').className =
        'vertical-indicator-container-fixed hide';
    } else if (this.getScrolled() >= 3) {
      document.getElementById('myPosition').className =
        'vertical-indicator-container-fixed show';
    }
    if (scrolled < 30) {
      document.getElementById('process').className =
        '-oneX-body--tertiary -oneX-font--semi';
    } else {
      document.getElementById('process').className =
        '-oneX-body--tertiary -oneX-font--med';
    }
    if (scrolled > 30 && scrolled < 70) {
      document.getElementById('benefits').className =
        '-oneX-body--tertiary -oneX-font--semi';
    } else {
      document.getElementById('benefits').className =
        '-oneX-body--tertiary -oneX-font--med';
    }
    if (scrolled > 70) {
      document.getElementById('help').className =
        '-oneX-body--tertiary -oneX-font--semi';
    } else {
      document.getElementById('help').className =
        '-oneX-body--tertiary -oneX-font--med';
    }
  }

  scrollToDestination(element: string) {
    const desktop = this.window.matchMedia('(min-height: 1100px)');
    const laptop = this.window.matchMedia('(min-height: 800px)');
    const tablet = this.window.matchMedia('(min-height: 576px)');
    let yOffset = 0;

    if (desktop && desktop.matches) {
      if (!(element.match('how-it-works') === null)) {
        yOffset += -280;
      } else {
        yOffset += -170;
      }
    } else if (laptop && laptop.matches) {
      if (!(element.match('how-it-works') === null)) {
        yOffset += -180;
      } else {
        yOffset += -80;
      }
    } else if (tablet && tablet.matches) {
      if (!(element.match('how-it-works') === null)) {
        yOffset += -140;
      } else {
        yOffset += -50;
      }
    }
    const elem = document.getElementById(element);
    const y =
      elem.getBoundingClientRect().top + this.window.pageYOffset + yOffset;
    this.window.scrollTo({ top: y });
  }

  multiplier(x) {
    return x;
  }

  @HostListener('window:scroll', [])
  scrollDirection() {
    if (window.screen.availWidth > 576) {
      let lastScrollTop = pageYOffset || document.documentElement.scrollTop;
      let scrollDown;
      let directionChanged = true;
      const value = this.multiplier(100);

      window.addEventListener(
        'scroll',
        function (e) {
          const winScroll =
            document.body.scrollTop || document.documentElement.scrollTop;
          const height =
            document.documentElement.scrollHeight -
            document.documentElement.clientHeight;
          const scrolled = (winScroll / height) * value;
          const currentScroll =
            window.pageYOffset || document.documentElement.scrollTop;
          directionChanged = scrollDown;

          if (currentScroll > lastScrollTop) {
            scrollDown = true;
          } else {
            scrollDown = false;
          }

          let midTop;

          if (directionChanged !== scrollDown) {
            if (scrolled > 30 && scrolled < 70 && scrollDown) {
              document.getElementById('progress-bar').className =
                'top-to-middle';
            } else if (scrolled < 30 && !scrollDown) {
              document.getElementById('progress-bar').className =
                'middle-to-top';
              midTop = true;
            }
            if (scrolled > 70 && scrollDown) {
              document.getElementById('progress-bar').className =
                'middle-to-bottom';
              midTop = false;
            } else if (
              scrolled > 30 &&
              scrolled < 70 &&
              !scrollDown &&
              !midTop
            ) {
              document.getElementById('progress-bar').className =
                'bottom-to-middle';
            }
            if (scrolled < 30 && !scrollDown && !midTop) {
              document.getElementById('progress-bar').className =
                'middle-to-top';
            }
          }
          directionChanged = scrollDown;
          lastScrollTop = currentScroll <= 0 ? 0 : currentScroll;
        },
        false
      );
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
