import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CliffLogDetails } from 'app/model/cliff-log-details.model';
import { LogDetails } from 'app/model/log-details.model';
import { CookieService } from 'ngx-cookie-service';
import { LogService } from './log.service';
import { exchangeOauth2Tokens } from "customer-oauth2-token-management-utility";
import { WindowReference } from 'app/model/window-reference.model';
import { UrlResolverService } from 'app/service/url-resolver.service';
import { AuthJwtTokenResponse, AuthJwtVerifyResponse } from 'app/model/auth-token-response.model';
import { InteractionsService } from './interactions.service';
import { catchError, map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable()
export class JWTTokenService {
  private cliffLog: CliffLogDetails;
  private logDetails: LogDetails;
  headers: HttpHeaders;

  constructor(
    private logService: LogService,
    private http: HttpClient,
    private cookieService: CookieService,
    private windowRef: WindowReference,
    private urlResolverService: UrlResolverService,
    private interactionsService: InteractionsService,
  ) {
  }

  public getOktaJWT() {
    return this.cookieService.get('sf-cauth1') ? `Bearer ${this.cookieService.get('sf-cauth1')}` :
      this.cookieService.get('sf-cauth-lite') ? `Bearer ${this.cookieService.get('sf-cauth-lite')}` : '';
  }

  public isJWTTokenExist(): boolean {
    return this.cookieService.check('sf-cauth1') || this.cookieService.check('sf-cauth-lite');
  }

  public async refreshJWTtoken() {
    let extClaimId = this.urlResolverService.getClaimId();
    let extClientId = this.urlResolverService.getClientId();

    if ((this.cookieService.get('sf-cauth1') && this.cookieService.get('sf-cauth2'))) {
      let jwtToken = await exchangeOauth2Tokens();
      if (!jwtToken) {
        this.logMessage('Unable to refresh OKTA JWT LOA4 token');
        this.windowRef.navigateTo(this.urlResolverService.buildProofingRedirectUrl("logout"));
      }
      else {
        this.logMessage('Successfully refreshed OKTA JWT LOA4 token');
      }
    } else {
      if (this.cookieService.get('sf-cauth-lite')) {
        return this.getJwtToken(extClaimId, extClientId).subscribe(
          (authJwtTokenResponse) => {
            this.setJWTToken('sf-cauth-lite', authJwtTokenResponse['token'], extClaimId, extClientId);
            this.logMessage('Successfully refreshed Lite JWT token');
          },
          catchError(error => {
            this.logMessage('Unable to refresh Lite JWT token', error);
            this.windowRef.navigateTo(this.urlResolverService.buildProofingRedirectUrl("logout"));
            return throwError(
              new Error(`Error Status is ${error.status.toString()}`)
            );
          }),
        );
      }
    }
  }

  public deleteJWTToken(externalClaimId = '', externalClientId = '') {
    this.cookieService.delete('sf-cauth1', '/', '.statefarm.com', true);
    this.cookieService.delete('sf-cauth2', '/', '.statefarm.com', true);
    this.cookieService.delete('sf-cauth-lite', '/', '.statefarm.com', true);
    this.logMessage('Successfully removed OKTA JWT tokens', externalClaimId, externalClientId);
  }

  public getJwtToken(externalClaimId: string, externalClientId: string): Observable<AuthJwtTokenResponse> {
    const jwtLoa2TokenUrl = this.windowRef.nativeWindow._config['jwtLoa2'].url;

    const httpOptions = {
      headers: new HttpHeaders({
        'x-api-key': 'sf-authentication-api-consumer-repair-assistant-ui',
        ...((!environment.production) && {'environment': 2})
      })
    };

    const url = `${jwtLoa2TokenUrl}/oauth2/token`.replace(/\s/g, '');

    const body = {
      extClaimId: externalClaimId,
      extClientId: externalClientId
    };

    return this.http.post<AuthJwtTokenResponse>(url, body, httpOptions).pipe(
      map(response => {
        this.interactionsService.setLOA(response['achievedLoa']);
        return response;
      }),
      catchError(error => {
        this.logMessage('failed to obtain OKTA JWT Lite Auth token', externalClaimId, externalClientId, error);
        return throwError(
          new Error(`Error Status is ${error.status.toString()}`)
        );
      }),
    );
  }

  public setJWTToken(tokenType: string, token: string, externalClaimId = '', externalClientId = '') {
    this.cookieService.set(tokenType, token, undefined, '/', '.statefarm.com', true);
    if (externalClaimId && externalClientId) {
      this.logMessage('Set new jwt-lite token in cookie ', externalClaimId, externalClientId);
    }
  }

  public getJwtVerify(token: string): Observable<AuthJwtVerifyResponse> {
    const jwtLoa2TokenUrl = this.windowRef.nativeWindow._config['jwtLoa2'].url;

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + token,
        'x-api-key': 'sf-authentication-api-consumer-repair-assistant-ui',
        ...((!environment.production) && {'environment': 2})
      }),
    };

    const url = `${jwtLoa2TokenUrl}/verifyJwt`;

    return this.http.get<AuthJwtVerifyResponse>(url, httpOptions).pipe(
      catchError(error => {
        this.logMessage('failed to verify OKTA JWT Lite Auth token', token, error);
        return throwError(
          new Error(`Error Status is ${error.status.toString()}`)
        );
      }),
    );
  }

  private logMessage(message: string, externalClaimId?: string, externalClientId?: string, error?: HttpErrorResponse) {
    this.cliffLog = new CliffLogDetails();
    this.logDetails = new LogDetails();
    this.cliffLog.message = message;
    if (externalClaimId) {
      this.cliffLog.claimId = externalClaimId;
    }
    if (externalClientId) {
      this.cliffLog.clientOrPartyId = externalClientId;
    }
    this.cliffLog.logData = this.logDetails;
    this.logService.sendLog(this.cliffLog);
  }
}
