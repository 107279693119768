import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { CommonResponse } from '../model/common-response.model';
import { ProviderRequest } from '../model/provider-request.model';
import { Provider } from '../model/provider.model';
import { UrlResolverService } from './url-resolver.service';

@Injectable()
export class ProvidersResourceService {
  private providersResourceUrl: string;
  private providers: Observable<Provider[]>;
  constructor(private http: HttpClient,
    private urlResolverService: UrlResolverService
  ) {
    this.providersResourceUrl = urlResolverService.getServiceUrl('providers');
  }

  public getProviders(requestParams: ProviderRequest) {
    this.providers = this.callProviders(requestParams);
    return this.providers;
  }

  private callProviders(requestParams: ProviderRequest): Observable<Provider[]> {
    
    //  no token for search only
    if (this.urlResolverService.getClientId()) {
    let httpOptions = {};
      httpOptions = {
        withCredentials: false,
        params: this.buildParams(requestParams)
      };
    
      const url = `${this.providersResourceUrl}?`;
      return this.http.get<CommonResponse<Provider>>(url, httpOptions).pipe(
        map(
          response => response.payload
        )
      ).pipe(
        catchError(
          (error: any, caught: Observable<any>) => {
            if (error.status) {
              if (error.status === 401) {
                error.statusText = 'Authorization Error';
              } else if (error.status === 500) {
                error.statusText = 'Error Calling Providers';
              } else if (error.status === 404) {
                return of([]);
              } else if (error.status === 400) {
                /** At times lat/long provided by geocoding api has extra long digits, hence API gives a 400. Eg: Ohio, USA.
                The extra digits are not populated when the API is called directly for the same criteria, from the google API doc. */
                return of([]);
              }
            } else {
              error.statusText = 'Unknown Error calling Providers';
            }
            throw error;
          }
        ),
      );
    }
  }

  private buildParams(request: ProviderRequest) {
    let params = new HttpParams();

    if (request.type === 'repairFacilities') {
      if (request.name) {
        params = params.set('name', request.name);
      } if (request.city) {
        params = params.set('city', request.city);
      } if (request.postalCode) {
        params = params.set('postalCode', request.postalCode);
      } if (request.state) {
        params = params.set('state', request.state);
      } if (request.radius) {
        params = params.set('radius', request.radius);
      } if (request.latitude) {
        params = params.set('latitude', request.latitude);
      } if (request.longitude) {
        params = params.set('longitude', request.longitude);
      }
      params = params.set('type', request.type);
      return params;
    }

    if (request.type === 'rental') {
      if (request.city) {
        params = params.set('driverCity', request.city);
      } if (request.state) {
        params = params.set('driverState', request.state);
      } if (request.postalCode) {
        params = params.set('driverZip', request.postalCode);
      } if (request.vendorType) {
        params = params.set('vendorType', request.vendorType);
      }
      params = params.set('type', 'rental');
      return params;
    }
  }
}
