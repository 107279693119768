import { AfterViewInit, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { contactTrigger, errorTrigger } from './photo-estimate-contact.component.animations';

// SERVICES
import { AuthService } from '../service/auth.service';
import { LogService } from '../service/log.service';
import { PhotoEstimateService } from '../service/photo-estimate.service';
import { ServiceClientsService } from '../service/service-clients.service';
import { UrlResolverService } from '../service/url-resolver.service';
import { WINDOW } from '../service/window.service';

// MODELS
import { ContactPreference } from '../model/contact-preference.model';
import { PhotoEstimateAssignment } from '../model/photo-estimate-assignment.model';
import { WindowReference } from '../model/window-reference.model';
import { CliffLogDetails } from '../model/cliff-log-details.model';
import { LogDetails } from '../model/log-details.model';
import { DataAnalyticsService } from 'app/service/data-analytics.service';

@Component({
  selector: 'app-photo-estimate-contact',
  templateUrl: './photo-estimate-contact.component.html',
  styleUrls: ['./photo-estimate-contact.component.css'],
  animations: [contactTrigger, errorTrigger]
})
export class PhotoEstimateContactComponent implements OnInit, AfterViewInit, OnDestroy {
  currentPage: string;

  cliffLog: CliffLogDetails;
  logDetails: LogDetails;

  externalClaimId: string;
  externalClientId: string;
  role: string;

  phone$: Observable<string>;
  email$: Observable<string>;

  isUserLoggedIn: boolean;
  isEditingPhone: boolean;
  isEditingEmail: boolean;

  phoneUpdated: boolean;
  emailUpdated: boolean;

  phoneView: string;
  emailView: string;
  errorView: string;

  retryCount: number;

  constructor(
    @Inject(WINDOW) private window: Window,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private clientService: ServiceClientsService,
    private logService: LogService,
    private photoEstimateService: PhotoEstimateService,
    private urlResolverService: UrlResolverService,
    private dataAnalyticsService: DataAnalyticsService,
    private windowRef: WindowReference
  ){ }

  ngOnInit(): void {
    this.currentPage = 'pe-contact';

    this.urlResolverService.populatePathParams(this.route);
    this.externalClaimId = this.urlResolverService.getClaimId();
    this.externalClientId = this.urlResolverService.getClientId();
    this.role = this.urlResolverService.getRole();

    this.phoneUpdated = false;
    this.emailUpdated = false;

    this.phoneView = 'closed';
    this.emailView = 'closed';
    this.errorView = 'closed';

    // If redirected here from proofing (customer logs in) or the page is refreshed,
    // call Repair Experience API
    if (this.checkIfRedirect() || !this.clientService.getExternalClaimId()) {
      this.callRepairExperience();
    }

    this.isUserLoggedIn = this.authService.isUserLoggedIn().value;

    this.phone$ = this.clientService.getPhone();
    this.email$ = this.clientService.getEmail();

    this.retryCount = 0;
  }

  ngAfterViewInit(): void {
    this.window['oneX'].addElement(document.querySelector(`#error-icon`));
    this.dataAnalyticsService.sendPageData('photo-estimate-contact');
  }

  checkIfRedirect(): boolean {
    let isRedirect = false;

    this.route.queryParams.subscribe(params => {
      isRedirect = params['redirect'] === 'true' ? true : false;
    });

    return isRedirect;
  }

  callRepairExperience() {
    if (this.externalClaimId && this.externalClientId) {
      this.clientService.initClientServices(this.externalClaimId, this.externalClientId, this.urlResolverService.getParticipantId());
    }
  }

  goToPhotoEstimateLanding() {
    this.router.navigate([
      'photoestimate/claim/' + this.externalClaimId + '/role/' + this.role + '/client/' + this.externalClientId
    ]);
  }

  goToProofing() {
    const proofingURL = this.urlResolverService.buildProofingRedirectUrl("login") + `?goto=` + this.urlResolverService.buildPEContactRedirectUrl();
    this.windowRef.navigateTo(proofingURL);
  }

  togglePhone() {
    this.phoneView = this.phoneView === 'open' ? 'closed' : 'open';
    if (this.errorView === 'open') {
      this.errorView = 'closed';
    }
    this.setPeLinkOption();
  }

  toggleEmail() {
    this.emailView = this.emailView === 'open' ? 'closed' : 'open';
    if (this.errorView === 'open') {
      this.errorView = 'closed';
    }
    this.setPeLinkOption();
  }

  setPeLinkOption() {
    if (this.phoneView == 'open' && this.emailView == 'open') {
      this.dataAnalyticsService.setPeLinkOption('text,email');
    } else if(this.phoneView == 'open' && this.emailView == 'closed') {
      this.dataAnalyticsService.setPeLinkOption('text');
    } else if(this.phoneView == 'closed'&& this.emailView == 'open') {
      this.dataAnalyticsService.setPeLinkOption('email');
    } else {
      this.dataAnalyticsService.setPeLinkOption('no')
    }
  }

  editContactInfo(contactOption: string) {
    this.isEditingPhone = contactOption === 'phone';
    this.isEditingEmail = contactOption === 'email';
  }

  savePhoneContact(event) {
    this.phone$ = of(event); // Convert the string to an observable.
    this.phoneUpdated = true;
    this.closeEditContact();
  }

  saveEmailContact(event) {
    this.email$ = of(event); // Convert the string to an observable.
    this.emailUpdated = true;
    this.closeEditContact();
  }

  cancelEditContact() {
    this.closeEditContact();
  }

  closeEditContact() {
    this.isEditingPhone = false;
    this.isEditingEmail = false;
  }

  sendPhotoEstimateAssignment() {
    if(this.retryCount > 0) {
      this.window['oneX'].Modal.hideModal(this, 'retry-modal');
    }

    if (this.phoneView === 'open' || this.emailView === 'open') {
      this.retryCount++;

      const peAssignmentObj: PhotoEstimateAssignment = new PhotoEstimateAssignment();
      const contactPreference: ContactPreference = new ContactPreference();

      // Determine contact preference
      contactPreference.email = this.emailView === 'open' ? true : false;
      contactPreference.phone = this.phoneView === 'open' ? true : false;
      peAssignmentObj.contactPreference = contactPreference;

      // Used to display on PE Confirmation page
      let contactPref = '';
      if (contactPreference.email && contactPreference.phone) {
        contactPref = 'email and text';
      } else if (contactPreference.email && !contactPreference.phone) {
        contactPref = 'email';
      } else if (!contactPreference.email && contactPreference.phone) {
        contactPref = 'text';
      }
      this.photoEstimateService.setContactPreference(contactPref);;

      // Determine if contact info has been updated
      if (this.emailUpdated) {
        this.email$.subscribe(email => peAssignmentObj.alternateEmail = email);
      }

      if (this.phoneUpdated) {
        this.phone$.subscribe(phone => peAssignmentObj.alternatePhone = phone);
      }


      // Replace existing assignment if one already exists
      peAssignmentObj.replaceExistingAssignment = false;

      // Call Photo Estimate API
      this.photoEstimateService.
        sendPhotoEstimateAssignment(
          this.externalClaimId,
          this.clientService.getVehicleNumber(),
          this.clientService.getParticipantId(),
          peAssignmentObj
        ).subscribe({
          next: status => {
            if (status === 201) {
              this.buildDefaultLog('RA_PE_ASSIGNMENT_SUCCESS', 'info', 'Photo Estimate Assignment Success: ' + contactPref);
              this.logDetails.httpRequest = 'Photo Estimate API - Send Assignment'
              this.logDetails.httpResponse = status.toString();
              this.sendLog();

              this.router.navigate(['peconfirm']);
            } else if (status === 200) {
              this.buildDefaultLog('RA_PE_ASSIGNMENT_ALREADY_EXISTS', 'info', 'Photo Estimate Assignment Already Exists');
              this.logDetails.httpRequest = 'Photo Estimate API - Send Assignment'
              this.logDetails.httpResponse = status.toString();
              this.sendLog();

              this.photoEstimateService.setPhotoEstimateError('assignment exists');
              this.router.navigate(['peerrorpage']);
            }
          },
          error: error => {
            this.buildDefaultLog('RA_PE_ASSIGNMENT_FAILURE','error', 'pe assignment failed, attempt count: ' + this.retryCount);
            this.logDetails.httpRequest = error.url;
            this.logDetails.httpResponse = error.status.toString();
            this.sendLog();

            // Pop retry modal if retry count < 3, otherwise go to error page
            if(this.retryCount < 3) {
              this.window['oneX'].Modal.showModal(this, 'retry-modal');
              this.dataAnalyticsService.sendPageData('photo-estimate-first-error');
            } else {
              this.photoEstimateService.setPhotoEstimateError('assignment failed');
              this.router.navigate(['peerrorpage']);
            }
          }
        });

    } else {
      // No selection has been made
      this.errorView = 'open';
    }
  }

  buildDefaultLog(messageId: string, logLevel: string, message: string) {
    this.cliffLog = new CliffLogDetails();
    this.logDetails = new LogDetails();
    this.cliffLog.messageId = messageId;
    this.cliffLog.logLevel = logLevel;
    this.cliffLog.message = message;
    this.cliffLog.claimId = this.externalClaimId;
    this.cliffLog.clientOrPartyId = this.externalClientId;
    this.logDetails.role = this.role;
    this.logDetails.currentPage = this.currentPage;
    this.logDetails.authentication = this.urlResolverService.getUrlParams().authentication;
  }

  sendLog() {
    this.cliffLog.logData = this.logDetails;
    this.logService.sendLog(this.cliffLog);
  }

  ngOnDestroy(): void {
    this.window['oneX'].removeElement(document.querySelector(`#error-icon`));
  }
}
