import { Injectable, OnDestroy } from '@angular/core';
import { Subject, BehaviorSubject, ReplaySubject, Observable } from 'rxjs';
import { first, map, takeUntil } from 'rxjs/operators';
import { SearchConstant } from '../constant/search-constants';

// SERVICES
import { ProvidersResourceService } from './providers-resource.service';
import { ServiceClientsService } from './service-clients.service';
import { StorageService } from './storage.service';
import { DataAnalyticsService } from './data-analytics.service';
import { LogService } from './log.service';
import { IioService } from './iio.service';
import { InteractionsService } from './interactions.service';
import { RepairExperienceService } from './repair-experience.service';
import { UrlResolverService } from './url-resolver.service';
import { ConversionService } from './conversion.service';

// MODELS
import { ProviderRequest } from '../model/provider-request.model';
import { SearchRequest } from '../model/search-request.model';
import { Provider } from '../model/provider.model';
import { FeatureFlags } from '../model/feature-flags.model';
import { SearchResultsResponse } from '../model/search-results-response';
import { Shop } from '../model/shop.model';
import { ChangeView } from '../model/change-view.model';
import { LogDetails } from 'app/model/log-details.model';
import { CliffLogDetails } from 'app/model/cliff-log-details.model';

@Injectable()
export class SearchResultsService implements OnDestroy {
  private ngUnsubscribe = new Subject();
  private cliffLog: CliffLogDetails;
  private logDetails: LogDetails;
  private logMessage: string;

  searchType: string;
  searchTerm: string;
  searchRequest: SearchRequest;
  providerRequest: ProviderRequest;
  searchResults: Provider[];
  radiusIndex: number;
  searchRadius: string;
  makeValue: string;
  radiusCount: number;
  searchResultsResponse: SearchResultsResponse;
  shops: Shop[];
  isEligibleForEstimates$: BehaviorSubject<boolean>;
  isEligibleForRepair$: BehaviorSubject<boolean>;
  isEligibleForIIO$: BehaviorSubject<boolean>
  isSearchReturned$: Subject<ChangeView> = new Subject<ChangeView>();
  flags$: ReplaySubject<FeatureFlags> = new ReplaySubject<FeatureFlags>();
  featureFlags: FeatureFlags;
  isRedirectPCA: boolean;
  isIIOflow: boolean;
  isCertShopsNotFound: boolean = false;
  isCertShopsSearch: boolean;
  changeView: ChangeView;
  private np_shops: string[] = [];


  constructor(
    private providersService: ProvidersResourceService,
    private dataAnalyticsService: DataAnalyticsService,
    private logService: LogService,
    private clientService: ServiceClientsService,
    private storageService: StorageService,
    private iioService: IioService,
    private interactionsService: InteractionsService,
    private repairExperienceService: RepairExperienceService,
    private urlResolverService: UrlResolverService,
    private conversionService: ConversionService
  ) {
    this.isIIOflow = false;
    this.searchRequest = new SearchRequest();
    this.providerRequest = new ProviderRequest();
    this.radiusCount = SearchConstant._DEFAULT_RADIUS_LABELS.long.length;
    this.searchResults = [];
    this.changeView = {
      destination: 'RA',
      shouldChange: true,
    };
    this.flags$ = this.clientService.callToFeatureFlagService();
    if (this.flags$) {
      this.flags$.subscribe(flags => {
        this.isRedirectPCA = flags.redirect_to_pca;
      });
    }
  }

  setSearchType(type) {
    this.searchRequest = new SearchRequest();
    let searchTypeName;
    switch (type) {
      case 'locality':
      case 'sublocality':
      case 'political':
      case 'neighborhood':
        searchTypeName = 'city';
        break;
      case 'premise':
      case 'route':
      case 'car_repair':
      case 'car_dealer':
      case 'street_address':
        searchTypeName = 'address';
        break;
      case 'postal_code':
        searchTypeName = 'postalCode';
        break;
      default:
        searchTypeName = 'postalCode';
        break;
    }
    this.searchType = searchTypeName;
    this.searchRequest.searchType = searchTypeName;

    this.logMessage = `google search type - ${type}`;
    this.logEvent('info', this.logMessage);
  }

  setValuesFromAddressComponents(addressComponents, formattedAddress) {
    for (let i = 0; i < addressComponents.length; i++) {
      switch (addressComponents[i].types[0]) {
        case 'street_number':
          this.searchRequest.streetNumber = addressComponents[i].long_name;
          break;
        case 'route':
          this.searchRequest.streetName = addressComponents[i].long_name;
          break;
        case 'locality':
        case 'sublocality':
        case 'political':
            // When Bronx is searched, google place api respond long_name as "The Bronx", which result in not returning any service shops.
            // if statement below remove "The " from long_name only for the bronx.
            if (
              addressComponents[i].long_name.toLowerCase().includes('the bronx')
            ) {
              this.searchRequest.city = addressComponents[i].long_name.slice(4);
            } else {
              this.searchRequest.city = addressComponents[i].long_name;
            }
          break;
        case 'neighborhood':  
          this.searchRequest.neighborhood = addressComponents[i].long_name;
          break;
        case 'administrative_area_level_1':
          this.searchRequest.state = addressComponents[i].short_name;
          break;
        case 'postal_code':
          this.searchRequest.postalCode = addressComponents[i].long_name;
          break;
        case '':
      }
    }
    this.searchRequest.streetAddress = this.searchRequest.streetNumber + ' ' + this.searchRequest.streetName;
    this.searchRequest.address = formattedAddress;
  }

  setYouAreHerePin(geometry) {
    this.changeView.latlng = {
      lat: geometry.location.lat(),
      lng: geometry.location.lng(),
    };
    this.searchRequest.latitude = geometry.location.lat();
    this.searchRequest.longitude = geometry.location.lng();
  }

  // post MVP include GeoCode Search
  // private geoCodeSearch() { }

  getSearchResults(searchTerm: string, radius: any, makeValue: string) {
    this.providerRequest = new ProviderRequest();
    this.providerRequest.isNameSearch = false;
    this.providerRequest.makeValue = makeValue;
    this.makeValue = makeValue;
    this.searchRadius = radius;
    this.setRadius(radius);
    this.providerRequest.isSearchAgain = false;
    this.searchTerm = searchTerm;
    this.searchRequest.searchTerm = searchTerm;
    this.providerRequest.searchTerm = searchTerm.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

    this.logMessage = 'getting search results';
    this.logEvent('info', this.logMessage);

    switch (this.searchType) {
      case 'address':
        this.searchByAddress();
        break;
      case 'postalCode':
        this.searchByZipCode();
        break;
      case 'city':
        this.searchByCityState();
        break;
    }
  }

  private searchByAddress() {
    // if search term starts with number
    if (/[0-9]/.test(this.searchTerm.substring(0, 1))) {
      // street address search
      this.searchByStreetAddress();
    } else {
      // for name search using shop name
      this.searchByName();
    }
  }

  private searchByStreetAddress() {
    if (this.searchRequest.city && this.searchRequest.state && this.searchRequest.streetNumber && this.searchRequest.streetName) {
      this.providerRequest.isStreetAddressSearch = true;
      this.providerRequest.state = this.searchRequest.state;
      this.providerRequest.city = this.searchRequest.city.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
      this.providerRequest.streetAddress = this.searchRequest.streetAddress;

      this.dataAnalyticsService.setSearchDetails(
        'address',
        this.providerRequest.radius
      );
      this.getRepairShops(this.providerRequest);
    } else {
      this.providerRequest.isStreetAddressSearch = false;
      this.searchByZipCode();
    }
  }

  private searchByName() {
    this.providerRequest.isNameSearch = true;
    this.providerRequest.filterBy = this.searchRequest.postalCode;
    this.providerRequest.name = this.searchTerm;
    this.providerRequest.state = this.searchRequest.state;
    this.getRepairShops(this.providerRequest);
    this.dataAnalyticsService.setSearchDetails(
      'name',
      this.providerRequest.radius
    );
  }

  private searchByZipCode() {
    if (this.searchRequest.postalCode && this.searchRequest.postalCode.length > 0) {
      this.providerRequest.isZipCodeSearch = true;
      this.providerRequest.postalCode = this.searchRequest.postalCode;

      this.dataAnalyticsService.setSearchDetails(
        'zip-code',
        this.providerRequest.radius
      );
      this.getRepairShops(this.providerRequest);
    } else {
      this.searchByLatLong();
    }
  }

  /** Whenever a zipcode is unavaialble for a search term, lat/long search is invoked.
   * Lat long search defaults to 75 miles radius, to fetch all the available shops (in an Island).
   * Expanded search(Auto expansion of radii) is not applicable for lat/long search.
   */
  private searchByLatLong() {
    this.setRadius('75 Miles');
    this.providerRequest.isZipCodeSearch = false;
    this.providerRequest.longitude = this.searchRequest.longitude;
    this.providerRequest.latitude = this.searchRequest.latitude;
    this.providerRequest.isLatLongSearch = true;
    this.getRepairShops(this.providerRequest);
  }

  private searchByCityState() {

    this.providerRequest.state = this.searchRequest.state;
    this.providerRequest.city = this.searchRequest.city.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    this.providerRequest.isCityStateSearch = true;
    this.dataAnalyticsService.setSearchDetails(
      'city-state',
      this.providerRequest.radius
    );
    this.getRepairShops(this.providerRequest);
  }

  getRepairShops(request: ProviderRequest) {
    // for bookmark
    if (request !== undefined) {
      request.type = 'repairFacilities';
      this.providerRequest = request;
      this.isEligibleForEstimates$ = this.clientService.isEligibleForEstimates();
      this.isEligibleForRepair$ = this.clientService.isEligibleForRepairAssignment();
      this.isEligibleForIIO$ = this.clientService.isEligibleForIIO();
      if (!request.isNameSearch) {
        if (this.providerRequest.makeValue && this.providerRequest.makeValue !== '' && this.providerRequest.makeValue !== 'Other' && this.radiusIndex !== 5){
          this.isCertShopsSearch = true;
          this.setRadius('75 Miles');
        } else {
          this.isCertShopsSearch = false;
        }
        this.repairExperienceService.getSearchLocatorFromThirdParties(this.urlResolverService.getClaimId(), this.urlResolverService.getClientId(), this.providerRequest).pipe(first()).subscribe(res => {
          const searchResults = this.conversionService.convertSearchLocatorDataToProviders(res);
          if (request.isCityStateSearch && request.isCityStateSearch.valueOf()) {
            this.handleCityStateSearchResult(request, searchResults, {
              city: this.providerRequest.city,
              state: this.providerRequest.state
            }, res);
            this.searchResults = searchResults;
          }
          if (request.isZipCodeSearch && request.isZipCodeSearch.valueOf()) {
            this.handleZipcodeSearchResult(request, searchResults, { zipcode: this.providerRequest.postalCode });
          }
          if (request.isLatLongSearch && request.isLatLongSearch.valueOf()) {
            this.emitChangeView(searchResults);
          }
          if (request.isStreetAddressSearch && request.isStreetAddressSearch.valueOf()) {
            this.handleAddressSearchResult(searchResults, res);
          }
        }, error => {
          this.logMessage =
            (error.status) ?
              `error calling searchLocator ${error.status}  ${error.statusText} ` :
              `error calling searchLocator ${error.statusText} `;
          this.logEvent('search Locator call error', this.logMessage);
        });
      } else {
        this.isCertShopsSearch = false;
        this.repairExperienceService.getSearchByNameFromThirdParties(this.urlResolverService.getClaimId(), this.urlResolverService.getClientId(), this.providerRequest).pipe(first()).subscribe(res => {
          const searchResults = this.conversionService.convertSearchByNameDataToProviders(res);
          // get the list of repair shops to be displayed in the search results page
          if (request.isNameSearch && request.isNameSearch.valueOf()) {
            if (
              searchResults &&
              searchResults.length === 0 &&
              this.isEligibleForEstimates$.getValue() &&
              this.isEligibleForRepair$.getValue() &&
              this.isRedirectPCA
            ) {
                // Check for Insured Inspection Options
                if (this.isEligibleForIIO$.getValue() && this.iioService.isDirectiveState(this.searchRequest.state)) {
                  this.isIIOflow = true;
                } else {
                  this.changeView.destination = 'PCA';
                  this.interactionsService.sendInteractions('Repair Assistant out of program error', 'Auto Repair');
                }
            }
            // for bookmark
            let postCode = request.filterBy;
            if (this.searchRequest && this.searchRequest.postalCode) {
              postCode = this.searchRequest.postalCode;
            }
            this.emitChangeView(searchResults);
          }
        }, error => {
          this.logMessage =
            (error.status) ?
              `error calling searchByName ${error.status}  ${error.statusText} ` :
              `error calling searchByName ${error.statusText} `;
          this.logEvent('search ByName call error', this.logMessage);
        });
      }
    }
  }

  handleCityStateSearchResult(request, searchResults, options, searchLocator){
    this.isCertShopsNotFound = false;
    // retry with neighborhood type if address is invalid for city
    if (searchResults.length === 0 && searchLocator.message[0].info === 'Address Not Found' && this.searchRequest.neighborhood && this.searchRequest.city !== this.searchRequest.neighborhood) {
      this.searchRequest.city = this.searchRequest.neighborhood;
      this.searchByCityState();
    } else if (searchResults.length === 0 && this.providerRequest.makeValue && this.providerRequest.makeValue !== '' && this.providerRequest.makeValue !== 'Other' ) {
      this.isCertShopsNotFound = true;
      this.emitChangeView(searchResults);
    } else if (searchResults.length < 3 && this.radiusIndex < 4) {
      // if minimum 3 results not found and current radius is within an allowable range, which is < 50 miles
      this.expandRadius();
      this.searchByCityState();
    } else {
      // if no results found and current radius is within allowable range, which is > 50 miles,
      //  and if it is transactional flow then estimate assist check will be enabled
      if (searchResults.length === 0 && this.urlResolverService.searchOnly === 'fullAccess' && this.radiusIndex >= 4) {
        // if repair assignments not exist
        if (this.getEstimateOptionsEligibility()) {
          // estimate assist check for rural area
          this.repairExperienceService.getEstimateAssistIndicator(
            this.urlResolverService.getClaimId(),
            this.urlResolverService.getClientId(),
            options
          ).pipe(
            first()
          )
            .subscribe(res => {
              if (res.estimateassist) {
                this.storageService.setSessionStorage('pr', this.urlResolverService.getStringifiedProviderRequest(request));
                this.setRadiusByIndex(this.radiusIndex);
                this.changeView.destination = 'estimateAssist';
                this.emitChangeView(searchResults);
              } else {
                // if estimate assist indicator is false and radius is 50 miles, then exand search
                if(this.radiusIndex === 4) {
                  this.expandRadius();
                  this.searchByCityState();
                } else {
                  this.emitChangeView(searchResults);
                }
              }
            }, error => {
              this.logMessage =
                (error.status) ?
                  `error calling estimate assist ${error.status}  ${error.statusText} ` :
                  `error calling estimate assist ${error.statusText} `;
              this.logEvent('estimate assist call error', this.logMessage);
              this.expandRadius();
              this.emitChangeView(searchResults);
            })
        } else {
          // if repair assignment exist
          if (this.radiusIndex !== 5) {
            this.expandRadius();
            this.searchByCityState();
          } else {
            this.changeView.destination = 'RA';
            this.emitChangeView(searchResults);
          }
        }
      } else {
        // If no results or minimum 3 results not found and it is search flow for radius >= 50 miles
        if (searchResults.length < 3 && this.radiusIndex !== 5) {
          this.expandRadius();
          this.searchByCityState();
        } else {
          this.emitChangeView(searchResults);
        }
      }
    }
  }


  handleZipcodeSearchResult(request, searchResults, options) {
    this.isCertShopsNotFound = false;
    if (searchResults.length === 0 && this.providerRequest.makeValue && this.providerRequest.makeValue !== '' && this.providerRequest.makeValue !== 'Other' ) { 
      this.isCertShopsNotFound = true;
      this.emitChangeView(searchResults);
    } else if (searchResults.length < 3 && this.radiusIndex < 4) {
      // if minimum 3 results not found and current radius is within an allowable range, which is < 50 miles
      this.expandRadius();
      this.searchByZipCode();
    } else {
      // if no results found and current radius is within allowable range, which is > 50 miles,
      //  and if it is transactional flow then estimate assist check will be enabled
      if (searchResults.length === 0 && this.urlResolverService.searchOnly === 'fullAccess' && this.radiusIndex >= 4) {
        // if repair assignments not exist
        if (this.getEstimateOptionsEligibility()) {
          // estimate assist check for rural area
          this.repairExperienceService.getEstimateAssistIndicator(
            this.urlResolverService.getClaimId(),
            this.urlResolverService.getClientId(),
            options
          ).pipe(
            first()
          )
            .subscribe(res => {
              if (res.estimateassist) {
                this.storageService.setSessionStorage('pr', this.urlResolverService.getStringifiedProviderRequest(request));
                this.setRadiusByIndex(this.radiusIndex);
                this.changeView.destination = 'estimateAssist';
                this.emitChangeView(searchResults);
              } else {
                // if estimate assist indicator is false and radius is 50 miles, then expand search
                if(this.radiusIndex === 4) {
                  this.expandRadius();
                  this.searchByZipCode();
                } else {
                  this.emitChangeView(searchResults);
                }
              }
            }, error => {
              this.logMessage =
                (error.status) ?
                  `error calling estimate assist ${error.status}  ${error.statusText} ` :
                  `error calling estimate assist ${error.statusText} `;
              this.logEvent('estimate assist call error', this.logMessage);
              this.expandRadius();
              this.emitChangeView(searchResults);
            }
            )
        } else {
          // if repair assignment exist
          if (this.radiusIndex !== 5) {
            this.expandRadius();
            this.searchByZipCode();
          } else {
            this.changeView.destination = 'RA';
            this.emitChangeView(searchResults);
          }
        }
      } else {
        // If no results or minimum 3 results not found and it is search flow for radius >= 50 miles
        if (searchResults.length < 3 && this.radiusIndex !== 5) {
          this.expandRadius();
          this.searchByZipCode();
        } else {
          this.emitChangeView(searchResults);
        }
      }
    }
  }

  handleAddressSearchResult(searchResults, searchLocator) {
    this.isCertShopsNotFound = false;
    if (searchResults.length === 0 && this.providerRequest.makeValue && this.providerRequest.makeValue !== '' && this.providerRequest.makeValue !== 'Other' ) { 
      this.isCertShopsNotFound = true;
      this.emitChangeView(searchResults);
    } else if (searchResults.length === 0 && searchLocator.message[0].info === 'Address Not Found' && this.searchRequest.neighborhood && this.searchRequest.city !== this.searchRequest.neighborhood) {
      // retry with neighborhood type if address is invalid for city
      this.searchRequest.city = this.searchRequest.neighborhood;
      this.searchByStreetAddress();
    } else if (searchResults.length < 3 && this.radiusIndex !== 5) {
      // if minimum 3 results not found and radius is not 75 miles
      this.expandRadius();
      this.searchByStreetAddress();
    } else if (searchResults.length === 0 && this.radiusIndex === 5) {
      // if no results found and readius reached 75 miles then zipcode search used
      this.providerRequest.isStreetAddressSearch = false;
      this.searchByZipCode();
    } else {
      this.emitChangeView(searchResults);
    }
  }

  emitChangeView(searchResults: Provider[]) {
    this.createSearchResponse(searchResults);
    this.changeView.shouldChange = true;
    this.isSearchReturned$.next(this.changeView);
  }

  getEstimateOptionsEligibility(): boolean {
    let estimateOptionsEligible = false;

    if (this.isEligibleForRepair$.getValue()) {
      this.clientService.getCurrentShopDetail()
        .pipe(
          takeUntil(this.ngUnsubscribe)
        ).subscribe(res => {
          if (!res) {
            estimateOptionsEligible = true;
          } 
        });
    } 
    return estimateOptionsEligible;
    
  }

  private createSearchResponse(searchResults: Provider[]) {
    this.searchResultsResponse = new SearchResultsResponse();
    this.shops = [];
    searchResults.forEach(provider => {
      const shop = new Shop();
      const phoneType = 'LANDLINE';
      const phoneUsage = 'Business';
      const phoneEquipmentType = 'Voice';
      shop.id = provider.id;
      shop.name = provider.name.last;
      shop.estimatingId = provider.estimatingId;
      shop.address = provider.address.line;
      shop.city = provider.address.city;
      shop.state = provider.address.state;
      shop.postalCode = provider.address.postalCode;
      shop.latitude = provider.address.latitude;
      shop.longitude = provider.address.longitude;
      shop.distance = this.getDistance(provider.distance);
      shop.performanceScore = provider.performanceScore;
      if (provider.certifications && provider.certifications.length > 0) {
        shop.certifications = provider.certifications;
      }
      shop.nationalProvider = provider.nationalProvider;
      shop.unavailable = provider.unavailable;
      for (const phone of provider.phones) {
        if (phone.type && phone.type === phoneType) {
          shop.phone = phone.area + '-' + phone.number;
          break;
        } else if (phone.usage === phoneUsage && phone.equipmentType === phoneEquipmentType) {
          shop.phone = phone.area + '-' + phone.number;
          break;
        } else {
          shop.phone = '';
        }
      }
      this.shops.push(shop);
    });
    if (
      (this.providerRequest.isSearchAgainByZipCode &&
        this.providerRequest.isSearchAgainByZipCode.valueOf() === true) ||
      (this.providerRequest.isNameSearch && this.shops.length > 1)
    ) {
      this.shops = this.checkShopsByFormattedAddress(this.shops);
    }
    const zipcode = this.searchRequest.postalCode;
    this.searchResultsResponse.shops = this.shops;
    this.searchResultsResponse.radius = this.providerRequest.radius;
    this.searchResultsResponse.isZipCodeSearch = this.providerRequest.isZipCodeSearch;
    this.searchResultsResponse.isNameSearch = this.providerRequest.isNameSearch;
    // postcode is required to load the map when the user loads CIO  using browser back/forward, from PCA.
    this.searchResultsResponse.zipCode = zipcode ? zipcode : this.providerRequest.postalCode;
    this.searchResultsResponse.searchTerm = (this.searchTerm) ? this.searchTerm : this.providerRequest.searchTerm;
    this.searchResultsResponse.searchRadius = (this.searchRadius) ? this.searchRadius : this.providerRequest.radius;
    this.searchResultsResponse.isRadiusExpanded = this.providerRequest.isSearchAgain;
    this.searchResultsResponse.displayShopsNotFound = this.displayShopNotFoundMsg(this.shops);
    this.searchResultsResponse.displayIIOMessage = this.isIIOflow;
    this.searchResultsResponse.displayCertShopsNotFound = this.isCertShopsNotFound;
    this.searchResultsResponse.isCertShopSearch = this.isCertShopsSearch;
    this.storageService.setSessionStorage(
      'searchResult',
      JSON.stringify(this.searchResultsResponse)
    );
    this.setSearchResultsResponse(this.searchResultsResponse);
  }
 
  private checkShopsByFormattedAddress(shops): Shop[] {
    const shopsToReturn = [];
    if (this.searchRequest.address) {
      shops.forEach(shop => {
        if (shopsToReturn.length < 1) {
          if (
            shop.address.trim() ===
            this.searchRequest.address
              .split(',')[0]
              .toUpperCase()
              .trim()
          ) {
            shopsToReturn.push(shop);
            this.providerRequest.isSearchAgainByZipCode = false;
          }
        }
      });
    }
    return shopsToReturn.length > 0 ? shopsToReturn : shops;
  }

  

  private getDistance(distance: string) {
    if (isNaN(Number(distance))) {
      return undefined;
    }
    return distance;
  }

  
  

  setSearchedCriteria(searchTerm: string, radius: string) {
    this.setRadius(radius);
    this.searchTerm = searchTerm;
  }

  setRadiusByIndex(index) {
    let r = SearchConstant._DEFAULT_RADIUS_LABELS.long[index];
    r = this.trimRadius(r);
    const searchRadi = r.trim();
    this.searchRequest.radius = searchRadi;
    this.providerRequest.radius = searchRadi;
  }

  setRadius(radius: string) {
    if (!/Miles/g.test(radius)) {
      radius = radius.split(' ')[0] + ' Miles';
    }
    this.radiusIndex = SearchConstant._DEFAULT_RADIUS_LABELS.long.indexOf(
      radius
    );
    radius = this.trimRadius(radius);
    const searchRadi = radius.trim();
    this.searchRequest.radius = searchRadi;
    this.providerRequest.radius = searchRadi;
  }

  private trimRadius(radiusLable: string) {
    let radius = radiusLable.replace('Miles', '');
    radius = radius.trim();
    return radius;
  }

  private expandRadius() {
    let radius =
      SearchConstant._DEFAULT_RADIUS_LABELS.long[this.radiusIndex + 1];
    radius = this.trimRadius(radius);
    this.searchRequest.radius = radius;
    this.providerRequest.radius = radius;
    this.radiusIndex = this.radiusIndex + 1;
    // to display error message
    this.providerRequest.isSearchAgain = true;
  }

  private displayShopNotFoundMsg(shops: any): boolean {
    // defaulted to ZipCode search
    if (
      this.providerRequest.isSearchAgainByZipCode &&
      this.providerRequest.isSearchAgainByZipCode.valueOf()
    ) {
      return true;
      // not eligible for repair
    } else if (
      this.providerRequest.isNameSearch &&
      !this.isEligibleForEstimates$.getValue() &&
      shops.length === 0
    ) {
      return true;
      // not eligible for pca
    } else if (
      this.providerRequest.isNameSearch &&
      !this.isEligibleForRepair$.getValue() &&
      shops.length === 0
    ) {
      return true;
    }
    return false;
  }

  getSearchResultsResponse(): SearchResultsResponse {
    return this.searchResultsResponse;
  }

  setSearchResultsResponse(providerResults: SearchResultsResponse) {
    this.searchResultsResponse = providerResults;
  }

  clearSearchResultsResponse() {
    this.searchResultsResponse = null;
  }

  getRequestPostalCode(): string {
    return this.searchRequest.postalCode;
  }

  getSearchRequest(): SearchRequest {
    return this.searchRequest;
  }

  setProviderRequest(providerRequest: ProviderRequest) {
    this.providerRequest = providerRequest;
  }

  getProviderRequest(): ProviderRequest {
    return this.providerRequest;
  }

  getSearchedCriteria() {
    return {
      searchTerm: this.searchTerm ? this.searchTerm : '',
      searchRadius: {
        index:
          this.radiusIndex !== undefined
            ? this.radiusIndex
            : SearchConstant._DEFAULT_RADIUS_LABELS_INDEX,
      },
      makeValue : this.makeValue
    };
  }
  isSearchReturned(): Subject<ChangeView> {
    return this.isSearchReturned$;
  }

  private logEvent(level: string, message: string) {
    this.cliffLog = new CliffLogDetails();
    this.logDetails = new LogDetails();
    this.cliffLog.logLevel = level;
    this.cliffLog.message = message;
    if (this.searchTerm) {
      this.logDetails.searchTerm = this.searchTerm;
      this.logDetails.searchType = this.searchType;
      this.logDetails.searchRadius = this.searchRadius;
    }
    this.cliffLog.logData = this.logDetails;
    this.logService.sendLog(this.cliffLog);
  }

  resetChangeView() {
    this.changeView.destination = 'RA';
    this.changeView.shouldChange = true;
  }

  getEstimateAssistShops(): Observable<any> {
    const eaShops = this.repairExperienceService.getEstimateAssistShopsFromThirdParties(this.urlResolverService.getClaimId(), this.urlResolverService.getClientId(), this.providerRequest);
    return eaShops.pipe(
      map(res => {
        const after_conversion = this.conversionService.convertThirdPartiesDataToProviders(res);
        this.createSearchResponse(after_conversion.providers);
        return {
          lat: after_conversion.lat,
          lng: after_conversion.lng
        };
      }));
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
