import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { CliffLogDetails } from 'app/model/cliff-log-details.model';
import { LogDetails } from 'app/model/log-details.model';
import { ProviderRequest } from '../model/provider-request.model';
import { UrlParams } from '../model/url-params.model';
import { WindowReference } from '../model/window-reference.model';
import { DataAnalyticsService } from './data-analytics.service';
import { LogService } from './log.service';
import { InteractionsService } from './interactions.service';
import { StorageService } from './storage.service';
import { ReplaySubject } from 'rxjs';

@Injectable()
export class UrlResolverService {
  appRedirectURL: string;
  category = '';
  searchOnly: string;
  termArray: string[] = ['isNameSearch', 'isZipCodeSearch', 'isLatLongSearch'];

  private cliffLog: CliffLogDetails;
  private logDetails: LogDetails;

  private external_claim_id_regex = new RegExp(
    '^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$'
  );
  private external_participant_id_regex = new RegExp(
    '^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$'
  );
  private external_client_id_regex = new RegExp('^[0-9a-zA-Z]{20}$');
  private role_regex = new RegExp('^[a-zA-Z]+$');
  private zip_code_regex = new RegExp('^[0-9]{5}$');
  private radius_regex = new RegExp('^[0-9]{1,2}$');
  private term_regex = new RegExp(`^[0-9a-zA-Z{}&-.,\' ]+$`);
  private vehicle_regex = new RegExp('^(0|[1-9][0-9]?|99)$');
  private urlParams$: ReplaySubject<UrlParams> = new ReplaySubject<UrlParams>(1);

  constructor(
    private dataAnalyticsService: DataAnalyticsService,
    private urlParams: UrlParams,
    private windowRef: WindowReference,
    private logService: LogService,
    private interactionsService: InteractionsService,
    protected storageService: StorageService,
  ) {

  }

  extractAndValidateFromUrl(params: Params, field: string) {
    let result = {
      isValid: false,
      value: '',
    };
    let val = params[field];
    if (val) {
      val = val.trim();
      switch (field) {
        case 'claim':
        case 'claimId':
          result = this.checkIfValid(this.external_claim_id_regex, val);
          break;
        case 'client':
        case 'clientId':
          result = this.checkIfValid(this.external_client_id_regex, val);
          break;
        case 'role':
          result = this.checkIfValid(this.role_regex, val);
          break;
        case 'participant':
        case 'participantId':
          result = this.checkIfValid(this.external_participant_id_regex, val);
          break;
        case 'zipCode':
          result = this.checkIfValid(this.zip_code_regex, val);
          break;
        case 'radius':
          result = this.checkIfValid(this.radius_regex, val);
          break;
        case 'term':
          result = this.checkIfValid(this.term_regex, val);
          break;
        case 'vehicle':
          result = this.checkIfValid(this.vehicle_regex, val);
          break;
      }
      if (!result.isValid) {
        console.log(`${field} is not a valid format`);
      }
    }
    return result.value;
  }

  private checkIfValid(regex: RegExp, val: string) {
    const v = {
      isValid: false,
      value: '',
    };
    if (regex.test(val)) {
      v.isValid = true;
      v.value = val;
    }
    return v;
  }

  populatePathParams(route: ActivatedRoute) {
    route.params.subscribe(params => {
      this.populateParams(params);
    });
  }

  populateQueryParams(route: ActivatedRoute) {
    route.queryParams.subscribe(params => {
      this.populateParams(params);
    });
  }

  private isSearchOnly(params: Params): Boolean {
    let paramClaim: string;
    if (params) {
      // if searchresults page is  bookmarked for searchOnly
      paramClaim = params['claim'];
      if (paramClaim && paramClaim.length !== 0) {
        return false;
      } else {
        return true;
      }
    }
    return true;
  }

  populateParams(params: Params) {
    this.searchOnly = (this.isSearchOnly(params)) ? 'readOnly' : 'fullAccess';
    this.urlParams.isBookmarked = false;
    if (!this.isSearchOnly(params)) {
      this.urlParams.externalClaimId = this.extractAndValidateFromUrl(params, 'claim');
      this.urlParams.role = this.extractAndValidateFromUrl(params, 'role');
      this.urlParams.externalClientId = this.extractAndValidateFromUrl(params, 'client');
      this.storageService.setSessionStorage('externalClaimId', this.urlParams.externalClaimId);
      this.storageService.setSessionStorage('externalClientId', this.urlParams.externalClientId);
      this.urlParams.participantId = this.extractAndValidateFromUrl(params, 'participantId');
      this.urlParams.vehicle = Number(this.extractAndValidateFromUrl(params, 'vehicle'));
      this.urlParams.zipCode = this.extractAndValidateFromUrl(params, 'zipcode');
      const termString = this.extractAndValidateFromUrl(params, 'term');
      this.urlParams.searchTerm = this.getSearchTermFromParams(termString);
      this.interactionsService.setClaimData(this.urlParams.externalClaimId, this.urlParams.externalClientId, this.urlParams.role);
      this.log('url params: get new lite token', this.urlParams.externalClientId);
    } else {
      this.log('url params: search only - no token needed');
    }
    this.urlParams.lat = Number(params['lat']);
    this.urlParams.lng = Number(params['lng']);

    this.urlParams$.next(this.urlParams);

    this.dataAnalyticsService.init(
      'repair-assistant',
      this.urlParams.authentication,
      this.urlParams.externalClaimId,
      this.urlParams.externalClientId,
      this.urlParams.role,
      this.searchOnly
    );

  }

  getServiceUrl(service: string) {
    const url = this.windowRef.nativeWindow._config[service].url;
    return url;
  }

  buildPEContactRedirectUrl() {
    const url = this.getServiceUrl('repairAssistant');
    this.appRedirectURL = `${url}/pecontact/claim/${this.urlParams.externalClaimId}/role/${this.urlParams.role}/client/${this.urlParams.externalClientId}?redirect=true`;
    return this.appRedirectURL;
  }

  buildHubRedirectUrl() {
    console.log('Build Links', this.urlParams.externalClaimId);
    const url = this.getServiceUrl('claimsHub');
    this.appRedirectURL = `${url}/claim/${this.urlParams.externalClaimId}/client/${this.urlParams.externalClientId}/role/${this.urlParams.role}`;
    return this.appRedirectURL;
  }

  buildHubRedirectUrlLandingB() {
    console.log('Build Links', this.urlParams.externalClaimId);
    const url = this.getServiceUrl('claimsHub');
    this.appRedirectURL = `${url}/claim/${this.urlParams.externalClaimId}/client/${this.urlParams.externalClientId}/role/${this.urlParams.role}/auto/milestones`;
    return this.appRedirectURL;
  }

  buildProofingRedirectUrl(option: string){
    console.log('Redirecting user to proofing URL');
    const url = this.getServiceUrl('proofing') + option;
    return url;
  }

  getIsAgent(): Boolean {
    return false;
  }

  getHostName(): string {
    return this.windowRef.nativeWindow.location.hostname;
  }

  getProtocol(): string {
    return this.windowRef.nativeWindow.location.protocol;
  }

  getClaimId() {
    return this.urlParams.externalClaimId;
    // TODO handle internal id; make call to get & return external
  }

  getRole() {
    return this.urlParams.role;
  }

  getClientId() {
    return this.urlParams.externalClientId;
    // TODO handle internal id; make call to get & return external
  }

  getParticipantId() {
    return this.urlParams.participantId;
  }

  getVehicleNumber() {
    return this.urlParams.vehicle;
  }

  getZipCode() {
    return this.urlParams.zipCode;
  }

  getHubRedirectUrl() {
    return this.appRedirectURL;
  }

  getPCAUrl(
    vehicleNumber: string,
    zipCode: string,
    refer?: string,
    searchTerm?: string
  ): string {
    const path = this.windowRef.nativeWindow._config['pca'].url;
    const pcaURL =
      path +
      '/vehicle-conditions/claim/' +
      this.getClaimId() +
      '/client/' +
      this.getClientId() +
      '/role/' +
      this.getRole() +
      '/vehicle/' +
      vehicleNumber +
      '/searchTerm/' +
      searchTerm +
      '?zipCode=' +
      zipCode +
      '&refer=' +
      refer;
    return pcaURL;
  }

  getPELandingUrl(
    vehicleNumber: string
  ): string {
    const path = this.windowRef.nativeWindow._config['pca'].url;
    const pcaURL =
      path +
      '/landing/claim/' +
      this.getClaimId() +
      '/client/' +
      this.getClientId() +
      '/role/' +
      this.getRole() +
      '/vehicle/' +
      vehicleNumber;
    return pcaURL;
  }

  getRAUrl() {
    const path = this.getProtocol() + '//' + this.getHostName();
    const shopsearchURL =
      path +
      '/shopsearch?claim=' +
      this.getClaimId() +
      '&role=' +
      this.getRole() +
      '&client=' +
      this.getClientId();
    return shopsearchURL;
  }

  getQueryParamsForUrl() {
    return { claim: this.getClaimId(), role: this.getRole(), client: this.getClientId() };
  }

  getStringifiedProviderRequest(providerRequest?: ProviderRequest): string {
    return providerRequest
      ? Object.entries(providerRequest)
        .map(([key, value]) => `${key}-${value}`)
        .join('&')
      : '';
  }

  getSearchTermFromParams(term: string): ProviderRequest {
    const terms = term ? term.split('&') : undefined;
    return this.populateProviderRequest(terms);
  }

  /**  Delimeter for negative numbers(ex: latitude--29.3535353)
   *  are replaced with a new delimeter(latitude_-29.3535353).
   *  split function can be overloaded to split by first occurance of '-',
   *  after Angular upgrade to v9.
   */
  private populateProviderRequest(terms?: string[]): ProviderRequest {
    const providerRequest = new ProviderRequest();
    if (terms) {
      let delimiter: any;
      terms.forEach(term => {
        delimiter = '-';
        if (term.indexOf('--') > -1) {
          term = term.replace('--', '_-');
          delimiter = '_';
        }
        const t = term.split(delimiter);
        // string param to boolean
        if (this.termArray.includes(t[0])) {
          providerRequest[t[0]] = JSON.parse(t[1]);
        } else {
          providerRequest[t[0]] = t[1];
        }
        if (t[0] === 'radius') {
          this.urlParams.radius = t[1];
        }
      });
    }
    return providerRequest;
  }

  getUrlParams(): UrlParams {
    return this.urlParams;
  }

  getUrlParamsObservable(): ReplaySubject<UrlParams> {
    return this.urlParams$;
  }

  getSearchedTermAndRadius(): { searchTerm: string; radius: string } {
    const result = {
      searchTerm: '',
      radius: this.urlParams.radius + ' mi',
    };
    /* const pr: ProviderRequest = this.urlParams.searchTerm;
    if (pr.postalCode) {
      result.searchTerm = pr.postalCode;
    } else if (pr.name) {
      result.searchTerm = pr.name + ', ' + (pr.state ? pr.state : '');
    } else if (pr.city) {
      result.searchTerm = pr.city + ', ' + (pr.state ? pr.state : '');
    }
    if (pr.searchTerm){
      result.searchTerm = pr.searchTerm + ', ' +  result.searchTerm ;
    }
    result.radius = pr.radius || result.radius;*/
    return result;
  }

  private log(message: string, externalClientId?: string) {
    this.cliffLog = new CliffLogDetails();
    this.logDetails = new LogDetails();
    this.cliffLog.message = message;
    if (externalClientId) {
      this.cliffLog.clientOrPartyId = externalClientId;
    }
    this.cliffLog.logData = this.logDetails;
    this.logService.sendLog(this.cliffLog);
  }

  // Method returns redirect URL to RA application landing page
  getRaRedirectURL() {
    const requestQuery = 'shopsearch?claim=' + this.getClaimId() + '&role=' + this.getRole() + '&client=' + this.getClientId();
    const raRedirectUrl = this.getRepairServiceUrl('repair.claims', requestQuery, false);
    return raRedirectUrl;
  }

  // Method for generating a URL for select service call.
  getRepairServiceUrl(pod: string, path: string, http: boolean): string {
    if (!pod) {
      return null;
    }

    const pathDefault = path ? path : '';
    const protocol = http ? 'http' : 'https';

    if (this.getHostName() === 'prod') {
      return `${protocol}://${pod}.statefarm.${this.getTopLevelDomain()}/${pathDefault}`;
    } else {
      return `${protocol}://${pod}.test.statefarm.${this.getTopLevelDomain()}/${pathDefault}`;
    }
  }

  // Method that returns top level domain
  getTopLevelDomain(): string {
    return 'com';
  }
}
