import { LogDetails } from './log-details.model';
export class CliffLogDetails {
  applicationId: String; // required
  messageId: String; // required
  logLevel: String; // required
  message: String;
  httpResponse?: Number; // THIS IS INTENTIALLY DUPLICATED; NUMERIC
  claimId?: String;
  clientOrPartyId?: String;
  participantId?: String;
  entryPoint?: String;
  logData: LogDetails; //  app specific K/V pairs

  constructor() {
    this.applicationId = 'repair-assistant';
    this.messageId = 'RAEC00';
    this.logLevel = 'info';
  }
}
