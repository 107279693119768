import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CliffLogDetails } from 'app/model/cliff-log-details.model';
import { LogDetails } from 'app/model/log-details.model';
import { CookieService } from 'ngx-cookie-service';
import { LogService } from './log.service';

@Injectable()
export class SSOTokenService {
  private cliffLog: CliffLogDetails;
  private logDetails: LogDetails;
  headers: HttpHeaders;

  constructor(
    private logService: LogService,
    private cookieService: CookieService,
  ) {
  }

  public getSSOToken() {
    return this.cookieService.check('sf-icp-sso') ? this.cookieService.get('sf-icp-sso') : '';
  }

  public isSSOTokenExist(): boolean {
    return this.cookieService.check('sf-icp-sso');
  }

  public deleteSSOToken(externalClaimId = '', externalClientId = '') {
    this.cookieService.delete('sf-icp-sso', '/', '.statefarm.com', true);
    this.log('remove expired tokens ', externalClaimId, externalClientId);
  }

  // TODO: log refector
  private log(message: string, externalClaimId?: string, externalClientId?: string) {
    this.cliffLog = new CliffLogDetails();
    this.logDetails = new LogDetails();
    this.cliffLog.message = message;
    if (externalClaimId) {
      this.cliffLog.claimId = externalClaimId;
    }
    if (externalClientId) {
      this.cliffLog.clientOrPartyId = externalClientId;
    }
    this.cliffLog.logData = this.logDetails;
    this.logService.sendLog(this.cliffLog);
  }
}
