import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { UrlParams } from 'app/model/url-params.model';
import { WindowReference } from '../model/window-reference.model';
import { CliffLogDetails } from 'app/model/cliff-log-details.model';
import { v1 as uuidv1 } from 'uuid';

@Injectable({
  providedIn: 'root',
})
export class LogService {
  correlationId: string;
  traceLogId: string;
  response: HttpResponse<String>;

  constructor(
    private http: HttpClient,
    private urlParams: UrlParams,
    private windowRef: WindowReference,
  ) {
    this.correlationId = uuidv1();
  }

  sendLog(cliffLogDetails: CliffLogDetails) {
    if (this.urlParams.externalClaimId) {
      cliffLogDetails.claimId = this.urlParams.externalClaimId;
    }

    const body = JSON.stringify(cliffLogDetails);

    return this.http
      .post(this.buildLogUrl(), body, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'x-api-key': this.windowRef.nativeWindow._config.logs.api,
          'correlationId': this.correlationId
        })
      })
      .subscribe(res => { }, error => { });
  }

  buildLogUrl() {
    return this.windowRef.nativeWindow._config.logs.url;
  }

  getCorrelationId() {
    return this.correlationId;
  }
}
