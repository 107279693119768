import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
  OnDestroy
} from '@angular/core';
import { ModalComponent } from '../modal/modal.component';
import { Shop } from '../model/shop.model';
import { ShopInfoService } from '../service/shop-info.service';
import { ServiceClientsService } from '../service/service-clients.service';
import { Observable, Subject } from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';
import { shopHours, shopHoursToggle } from './shop-card.component.animations';
import { DataAnalyticsService } from '../service/data-analytics.service';
import { LogDetails } from 'app/model/log-details.model';
import { LogService } from 'app/service/log.service';
import { CliffLogDetails } from 'app/model/cliff-log-details.model';
import { UrlResolverService } from '../service/url-resolver.service';
import { WindowReference } from '../model/window-reference.model';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-shop-card',
  templateUrl: './shop-card.component.html',
  styleUrls: ['./shop-card.component.css'],
  animations: [shopHours, shopHoursToggle],
})
export class ShopCardComponent implements OnInit, OnDestroy {
  @ViewChild('confirmation', { static: false }) confirmationModal: ModalComponent;
  @Input() shop: Shop;
  @Input() index: number;
  @Input() isSelected: boolean;
  @Input() isSidebarVisabile: boolean;
  @Input() isEstimateAssist: boolean;
  @Output() selection: EventEmitter<Shop> = new EventEmitter<Shop>();
  @Output() useForClaim: EventEmitter<Shop> = new EventEmitter<Shop>();

  isShopDetailsVisible: boolean;
  isDeviceDesktop: boolean;
  userIsEligible$: Observable<boolean>;
  currentPage = 'ra-search-results';
  isSecondaryAssignment: boolean;
  actorId: String;
  private ngUnsubscribe = new Subject();

  distancePluralMapping = {
    distance: {
      '=0': '0 miles',
      '=1': '1 mile',
      other: '# miles',
    },
  };

  reviewPluralMapping = {
    reviews: {
      '=0': '0 Google Reviews',
      '=1': '1 Google Review',
      other: '# Google Reviews',
    },
  };


  constructor(
    private shopInfoService: ShopInfoService,
    private clientService: ServiceClientsService,
    private deviceService: DeviceDetectorService,
    private urlResolverService: UrlResolverService,
    private dataAnalyticsService: DataAnalyticsService,
    private windowRef: WindowReference,
    private logService: LogService,
  ) {
  }

  ngOnInit() {
    this.userIsEligible$ = this.clientService.isEligibleForRepairAssignment();
    this.clientService.getCurrentShopDetail()
      .pipe(
        takeUntil(this.ngUnsubscribe)
      ).subscribe(res => {
        if (res) {
          this.actorId = res.ActorID;
          this.isSecondaryAssignment = true;
        } else {
          this.isSecondaryAssignment = false;
        }
      });

    this.isDeviceDesktop = this.deviceService.isDesktop();
    this.shop.address = this.shopInfoService.correctShopAddressFormat(this.shop.address);
  }

  getCertifications() {
    const certificationsArray: string[] = [];
    let certifications: string;
    if (this.shop.certifications) {
      for (let i = 0; i < this.shop.certifications.length; i++) {
        certificationsArray.push(Object.values(this.shop.certifications[i])[0]);
      }
      certifications = certificationsArray.sort().join(', ');
    }
    return certifications;
  }

  closeShopHoursVisability() {
    this.isShopDetailsVisible = false;
  }

  toggleShopHoursVisability() {
    this.isShopDetailsVisible = !this.isShopDetailsVisible;
  }

  shopCardClicked(shop: Shop) {
    this.shop.clicked = true;
    this.selection.emit(this.shop);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  promptForUserConfirmation() {
    this.dataAnalyticsService.setShopUnavailability(this.shop.unavailable);

    if (this.actorId === this.shop.id) {
      this.windowRef.navigateTo(this.urlResolverService.buildHubRedirectUrl());
    } else {
      this.useForClaim.emit(this.shop);

      const cliffLog = new CliffLogDetails();
      const logDetails = new LogDetails();

      logDetails.confirmShopModalDisplayed = 'true';
      logDetails.shopAssignmentProgress = 'confirm-modal-displayed';
      logDetails.currentPage = this.currentPage;
      cliffLog.logData = logDetails;

      this.logService.sendLog(cliffLog);

      if (this.isEstimateAssist) {
        this.dataAnalyticsService.sendPageData(this.dataAnalyticsService.setPageDataWithCount('estimate-assist-use-this-shop', this.clientService.assignmentCount));
      } else {
        if (this.shop.unavailable) {
          this.dataAnalyticsService.sendPageData('still-use-this-shop-modal');
        } else {
          this.dataAnalyticsService.sendPageData(this.dataAnalyticsService.setPageDataWithCount('use-this-shop', this.clientService.assignmentCount));
        }
      }
      if (this.shop.certifications) {
        this.dataAnalyticsService.setVehicleCert('yes');
      } else {
        this.dataAnalyticsService.setVehicleCert('no');
      }
      if (this.shop.nationalProvider) {
        this.dataAnalyticsService.setNationalCert('yes');
      } else {
        this.dataAnalyticsService.setNationalCert('no');
      }
    }
  }
}
