import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { LogService } from './log.service';
import { UrlResolverService } from './url-resolver.service';
import { HelpResponse } from 'app/model/help.model';
import { SSOTokenService } from './sso.token.service';
import { JWTTokenService } from './jwt.token.service';
import { CliffLogDetails } from 'app/model/cliff-log-details.model';
import { LogDetails } from 'app/model/log-details.model';

@Injectable({
  providedIn: 'root'
})
export class HelpService {

  public HELP_SERVICE_URL: string;
  private cliffLog: CliffLogDetails;
  private logDetails: LogDetails;
  headers: HttpHeaders;
  private helpDataKey = 'helpData';

  constructor(
    private http: HttpClient,
    private urlResolverService: UrlResolverService,
    private logService: LogService,
    private ssoTokenService: SSOTokenService,
    private jwtTokenService: JWTTokenService,
  ) {
    this.HELP_SERVICE_URL = this.urlResolverService.getServiceUrl('api_help');
  }

  public getHelpData(
    claimId: string,
    roleType: string,
    lob: string,
    extClientId: string,
  ): Observable<HelpResponse> {
    const helpUrl = `${this.HELP_SERVICE_URL}/claims/${claimId}?queryType=statusAndCategories&roleType=${roleType}&lob=${lob}&extClientId=${extClientId}&testEnvLane=env2`;

    let httpOptions = {}

    const ssoToken= this.ssoTokenService.getSSOToken();
    const jwtToken= this.jwtTokenService.getOktaJWT();

    this.headers = new HttpHeaders({
      'correlation-id': this.logService.getCorrelationId(),
      ...((ssoToken && ssoToken != '') && {'X-SF_SSO_TOKEN': ssoToken}),
      ...((jwtToken && jwtToken != '') && {Authorization: jwtToken}),
    });

    httpOptions = {
      headers: this.headers, withCredentials: false
    }

    return this.http.get<HelpResponse>(helpUrl, httpOptions).pipe(
      catchError((error) => {
        this.logMessage("Failure in receiving help topics","error", error);
      return this.handleHelpError(error.error);
    }),
      tap((data) => {
        this.storeHelpDataInSession(data);
        this.logMessage("Landing Page B - Received help topics","info", );
      }),
    );
  }

  storeHelpDataInSession(helpResponse: HelpResponse) {
    sessionStorage.setItem(this.helpDataKey, JSON.stringify(helpResponse));
  }

  getHelpDataFromSession(): HelpResponse {
    return JSON.parse(sessionStorage.getItem(this.helpDataKey));
  }

  private handleHelpError(err: any) {
    return throwError(err);
  }

  private logMessage(message: string, logLevel: string, error?: HttpErrorResponse) {
    this.cliffLog = new CliffLogDetails();
    this.logDetails = new LogDetails();
    this.cliffLog.logLevel = logLevel;
    this.cliffLog.message = message;
    this.cliffLog.logData = this.logDetails;
    this.logService.sendLog(this.cliffLog);
  }

}
