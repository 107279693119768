import { Injectable } from '@angular/core';
import { WindowReference } from 'app/model/window-reference.model';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpResponse,
} from '@angular/common/http';
import { AuthTokenResponse } from 'app/model/auth-token-response.model';
import { map, catchError } from 'rxjs/operators';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { CliffLogDetails } from 'app/model/cliff-log-details.model';
import { LogDetails } from 'app/model/log-details.model';
import { LogService } from './log.service';
import { UserDetailsResponse } from 'app/model/user-details-response.model';
import { RepairExperienceService } from './repair-experience.service';
import { UrlResolverService } from './url-resolver.service';
import { UrlParams } from 'app/model/url-params.model';
import { InteractionsService } from './interactions.service';

@Injectable()
export class AuthService {

  // a state
  private userLoggedIn$: BehaviorSubject<boolean>;

  constructor(
    private windowRef: WindowReference,
    private http: HttpClient,
    private logService: LogService,
    private repairExperienceService: RepairExperienceService,
    private urlResolverService: UrlResolverService,
    private urlParams: UrlParams,
    private interactionsService: InteractionsService,
  ) {
    this.userLoggedIn$ = new BehaviorSubject(false);
  }

  public setUserLoggedIn(status: boolean) {
    this.urlParams.authentication = status ? 'full' : 'partial';
    this.userLoggedIn$.next(status);
    this.urlResolverService.buildHubRedirectUrl();
  }

  public isUserLoggedIn(): BehaviorSubject<boolean> {
    return this.userLoggedIn$;
  }

  public authenticateUser(
    externalClaimId: string,
    externalClientId: string
  ): Observable<HttpResponse<UserDetailsResponse>> {
    return this.repairExperienceService.getUserDetails(externalClaimId, externalClientId).pipe(
      map(response => {
        if(response.body['Identity'].loa){
          this.logUserDetails(
            response.body['Identity'].loa,
            response.body['Identity'].affiliation,
            externalClaimId,
            externalClientId
          );
        }

        return response;
      }),
      catchError(error => {
        const cliffLog = new CliffLogDetails();
        const logDetails = new LogDetails();
        cliffLog.message = 'failed to retrieve user details';
        logDetails.httpRequest = error.url;
        logDetails.httpResponse = `${error.status.toString()}`;
        cliffLog.httpResponse = error.status;
        cliffLog.logData = logDetails;
        this.logService.sendLog(cliffLog);
        return throwError(
          new Error(`Error Status is ${error.status.toString()}`)
        );
      })
    );
  }

  // TODO: log refactor
  private log(
    message: string,
    externalClaimId?: string,
    externalClientId?: string,
    error?: HttpErrorResponse
  ) {
    const cliffLog = new CliffLogDetails();
    const logDetails = new LogDetails();
    cliffLog.message = message;
    if (externalClaimId) {
      cliffLog.claimId = externalClaimId;
    }
    if (externalClientId) {
      cliffLog.clientOrPartyId = externalClientId;
    }
    if (error) {
      logDetails.httpRequest = error.url;
      logDetails.httpResponse = `${error.status.toString()}`;
      cliffLog.httpResponse = error.status;
    }
    cliffLog.logData = logDetails;
    this.logService.sendLog(cliffLog);
  }

  private logUserDetails(loa: number, affiliation: string, externalClaimId: string, externalClientId: string) {
    const cliffLog = new CliffLogDetails();
    const logDetails = new LogDetails();

    const message = `authenticated user: LOA${loa}, ${affiliation} for external claim id: ${externalClaimId} and external client id: ${externalClientId}`;
    cliffLog.message = message;

    cliffLog.logData = logDetails;
    this.logService.sendLog(cliffLog);
    this.interactionsService.setLOA(loa.toString());
  }
}
