import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { map } from 'rxjs/operators';

// SERVICES
import { LogService } from './log.service';
import { SSOTokenService } from './sso.token.service';
import { JWTTokenService } from './jwt.token.service';
import { UrlResolverService } from './url-resolver.service';

// MODELS
import { PhotoEstimateEligibility } from '../model/photo-estimate.model';
import { PhotoEstimateAssignment } from 'app/model/photo-estimate-assignment.model';
import { CliffLogDetails } from '../model/cliff-log-details.model';
import { LogDetails } from '../model/log-details.model';

@Injectable({
  providedIn: 'root',
})
export class PhotoEstimateService {
  private photoEstimateUrl: string;
  private contactPreference: string;
  private photoEstimateError: string;

  constructor(private http: HttpClient,
    private urlResolverService: UrlResolverService,
    private ssoTokenService: SSOTokenService,
    private jwtTokenService: JWTTokenService,
    private logService: LogService,
  ) {
    this.photoEstimateUrl = this.urlResolverService.getServiceUrl('photoEstimate');
  }

  public sendPhotoEstimateAssignment(
    extClaimId: string, 
    vehicleNum: string, 
    extParticipantId: string, 
    peAssignmentObj: PhotoEstimateAssignment
  ): Observable<number> {
    let url = `${this.photoEstimateUrl}/${extClaimId}/vehicles/${vehicleNum}/assignment`;

    if (extParticipantId) {
      url = `${url}?extParticipantId=${extParticipantId}`;
    }

    const headers = this.setHttpHeaders();
    const httpOptions : Object = { headers: headers, observe: 'response' };

    return new Observable(observer => {
      this.http
        .post<any>(url, peAssignmentObj, httpOptions)
        .subscribe( 
          response => {
            observer.next(response.status);
        },
        error => {
          if (error.status === 401) {
            error.statusText = 'Authentication Error Calling Photo Estimate API';
          } else if (error.status === 500) {
            error.statusText = 'Error Calling Photo Estimate API';
          } else if (error.status === 400) {
            error.statusText = 'Validation Error Calling Photo Estimate API';
          } else {
            error.statusText = 'Unknown Error calling Photo Estimate API';
          }
          observer.error(error);
        }
      );
    });
  };

  public getEligibility(externalClaimId: string, vehicleNum: string, extParticipantId: string): Observable<PhotoEstimateEligibility> {
    let url = `${this.photoEstimateUrl}/${externalClaimId}/vehicles/${vehicleNum}/assignment/eligibility`;

    if (extParticipantId) {
      url = `${url}?extParticipantId=${extParticipantId}`;
    }   

    const headers = this.setHttpHeaders();
    const httpOptions = {headers: headers};

    this.buildAndSendLog('RA_PE_ASSIGNMENT_ELIGIBILITY_REQUEST', 'info', 'Photo Estimate Assignment Eligibility request initiated', externalClaimId, url, undefined);

    return this.http.get<PhotoEstimateEligibility>(url, httpOptions).pipe(
      map(
        response => response
      )
    ).pipe(
      catchError(
        (error: any, caught: Observable<any>) => {
          if (error.status === 401) {
            error.statusText = 'Authentication Error Calling Photo Estimate API';
          } else if (error.status === 500) {
            error.statusText = 'Error Calling Photo Estimate API';
          } else if (error.status === 400) {
            error.statusText = 'Validation Error Calling Photo Estimate API';
          } else {
            error.statusText = 'Unknown Error calling Photo Estimate API';
          }

          this.buildAndSendLog('RA_PE_ASSIGNMENT_ELIGIBILITY_REQUEST_FAILED', 'error', 'Photo Estimate Assignment Eligibility request failed', externalClaimId, url, error.status);

          throw error;
        }
      ),
    );
  }

  setHttpHeaders() {
    const ssoToken = this.ssoTokenService.getSSOToken();
    const jwtToken = this.jwtTokenService.getOktaJWT();

    const headers = new HttpHeaders({
      'correlation-id': this.logService.getCorrelationId(),
      'x-api-key': 'sf-photo-estimate-api-consumer-repair-assistant',
      ...((ssoToken && ssoToken != '') && {'X-SF_SSO_TOKEN': ssoToken}),
      ...((jwtToken && jwtToken != '') && {Authorization: jwtToken}),
    });
    
    return headers;
  }

  setContactPreference(contactPreference: string) {
    this.contactPreference = contactPreference;
  }

  getContactPreference(): string {
    return this.contactPreference;
  } 

  setPhotoEstimateError(peError: string) {
    this.photoEstimateError = peError;
  }

  getPhotoEstimateError(): string {
    return this.photoEstimateError;
  }

  buildAndSendLog(messageId: string, logLevel: string, message: string, externalClaimId: string, httpRequest: string, httpResponse: string) {
    let cliffLog = new CliffLogDetails();
    let logDetails = new LogDetails();

    logDetails.httpRequest = httpRequest;
    logDetails.httpResponse = httpResponse;    

    cliffLog.messageId = messageId;
    cliffLog.logLevel = logLevel;
    cliffLog.message = message;
    cliffLog.claimId = externalClaimId;
    cliffLog.logData = logDetails;

    this.logService.sendLog(cliffLog);
  }
}
