export class LogDetails {
  role?: String;
  stateCode?: String;
  adspContentCode?: String;
  authentication?: String;
  currentPage?: String;
  searchOnly?: String;
  navigatingTo?: String;
  devicePlatform?: String; // desktop or mobile
  browser?: String;
  browserVersion?: String;
  os?: String;
  osVersion?: String;
  searchTerm?: String;
  searchRadius?: String;
  searchType?: String;
  googleShopReview?: String;
  confirmationPath?: String;
  shopAssignmentProgress?: String;
  confirmShopModalDisplayed?: String;
  confirmedShop?: String;
  repairAssignmentCompleted?: String;
  httpResponse?: String; // string for dashboard
  httpRequest?: String;
  roleType?: String; // named-insured, claimant, claimant-insured
  searchResultMessage?: String;
  residenceState?: String; // residence state of the participant
  claimantProgress?: String;
  rentalVehicleClass?: String;
  rentalVendor?: String;

  constructor() {}
}
