<!-- Main page -->
<div class="-oneX -oneX-container-fluid page-container">
    <!-- Shop information column -->
    <div class="shop-info-container">
        <div class="-oneX-row shop-info-section">

            <!-- Search bar. Desktop & tablet only -->
            <div *ngIf="!isMobile" #searchBarDesktopContainer class="search-container">
                <app-search
                    [currentPage]="currentPage"
                    [isViewStatic]="isViewStatic"
                    [searchResultsPage]="true"
                    (changetoResultView)="changetoResultView.emit($event)">
                </app-search>
            </div>

            <!-- Navigation text. Desktop & tablet only -->
            <div *ngIf="!isMobile" class="section-container-no-border navigation-spacing">
                <div class="-oneX-row">
                    <a class="-oneX-link--inline -oneX-link-secondary" href="javascript:void(0)" (click)="closeShopDetails()">View list results</a>
                </div>
            </div>

            <!-- Shop image -->
            <div class="image-container"
                 [style.background-image]="(shop?.placeDetails?.photo) ? 'url(' + shop.placeDetails.photo + ')' : ''">

                <!-- Search bar. Mobile only -->
                <div *ngIf="isMobile" #searchBarMobileContainer class="search-container-mobile">
                    <app-search
                        [currentPage]="currentPage"
                        [isViewStatic]="isViewStatic"
                        [isSidebarVisabile]="true"
                        (changetoResultView)="changetoResultView.emit($event)">
                    </app-search>
                </div>

                <!-- Default image when shop image is not available -->
                <div class="-oneX-icon-container image-subcontainer" [style.display]="(!shop?.placeDetails?.photo) ? '' : 'none'">
                    <div class="-oneX-icon image-dimensions icon-bg-gray-03" data-icon="image_file_32"></div>
                    <span class="-oneX-icon-title -oneX-body--primary">Image not available</span>
                </div>

            </div>

            <!-- Shop name -->
            <div class="shop-name-section-container">
                <div id="shopName" class="-oneX-row section-row">
                    <div class="-oneX-body--primary font-semibold">
                        {{shop.name | titlecase}}
                    </div>
                </div>

                <!-- Ratings/Reviews -->
                <div class="-oneX-row">
                    <div>
                        <app-shop-star-rating id="shopRating" [location]="shop?.placeDetails" [isSidebarVisabile]="true"></app-shop-star-rating>
                    </div>
                </div>
            </div>

            <div class="section-container address-spacing">
                <!-- Address -->
                <div class="-oneX-row section-row location-row">
                    <div class="-oneX-icon-container icon-dimensions center-align">
                        <div class="-oneX-icon icon-dimensions" data-icon="location_24"> </div>
                    </div>

                    <div id="shopAddress"class="-oneX-body--secondary location-text-container">
                        <span class="location-text">{{shop.address}}, {{shop.city | titlecase}}, {{shop?.state}} {{shop.postalCode | slice:0:5}}</span>
                    </div>
                </div>
                
                <!-- Phone -->
                <div class="-oneX-row section-row location-row">
                    <div class="-oneX-icon-container icon-dimensions center-align">
                        <div class="-oneX-icon icon-dimensions" data-icon="phone_24"> </div>
                    </div>

                    <div *ngIf="!isMobile" class="-oneX-body--secondary location-text-container">
                        <span id="shopPhoneText" class="location-text">{{shop.phone | phoneNumber}}</span>
                    </div>

                    <div *ngIf="isMobile" class="-oneX-body--secondary location-text-container">
                        <a id="shopPhoneLink" href="tel:{{shop.phone}}" target="_blank" class="-oneX-body--secondary phone-text">{{shop.phone | phoneNumber}}</a>
                    </div>
                </div>

                <!-- Hours -->
                <!-- Need to use the conditional styling instead of ngIf because 1x icons do not work very well with SPAs. The ngIf sometimes causes 1x
                    to not see the element briefly because the element is conditionally added during init -->
                <div id="shopHoursSection" class="-oneX-row section-row location-row selectable-decoration"
                     tabindex="0"
                     [style.display]="(shop?.placeDetails?.open_now) ? '' : 'none'"
                     (click)="toggleShopHoursVisability()"
                     (keydown.enter)="toggleShopHoursVisability()">
                    <div class="-oneX-icon-container icon-dimensions center-align">
                        <div class="-oneX-icon icon-dimensions" data-icon="clock_24"> </div>
                    </div>

                    <div class="-oneX-body--secondary location-text-container">
                        <!-- TODO: Hours summary is hardcoded for now. Dynamic summary hours will be implemented in another story -->
                        <span class="-oneX-typography-variant4 location-text">
                            {{shop?.placeDetails?.open_status}}
                        </span>
                        <span class="location-text">&nbsp;&bull;&nbsp;&nbsp;{{shop?.placeDetails?.open_now_alt}}</span>
                    </div>

                    <!-- Down chevron -->
                    <div *ngIf="!isShopHoursVisible" class="-oneX-icon--chevron--down chevron"></div>

                    <!-- Up chevron -->
                    <div *ngIf="isShopHoursVisible" class="-oneX-icon--chevron--up chevron"></div>
                </div>

                <!-- Full shop hours toggle display -->
                <div class="-oneX-row shop-hours-container" [@shopHours]="isShopHoursVisible ? 'open' : 'closed'">
                    <table>
                    <tr *ngFor="let item of shop?.placeDetails?.weekday_text; index as i">
                        <td class="-oneX-body--secondary shop-hours-day"
                            [ngClass]="{'font-semibold': shop?.placeDetails?.dayIndexToday === i}"> {{shop?.placeDetails?.day[i]}} &nbsp; </td>
                        <td class="-oneX-body--secondary shop-hours-hours"
                            [ngClass]="{'font-semibold': shop?.placeDetails?.dayIndexToday === i}">{{item}}</td>
                    </tr>
                    </table>
                </div>                    
                
                <!-- No appointments -->
                <div class="-oneX-row section-row location-row" [style.display]="(shop.unavailable) ? '' : 'none'">
                    <div class="-oneX-icon-container icon-dimensions center-align">
                        <div class="-oneX-icon no-appointment-icon" data-icon="close_24"> </div>
                    </div>

                    <div class="-oneX-body--secondary font-semibold no-appointment-text-container">
                        <span class="location-text">Appointments available for drivable vehicles only</span>
                    </div>
                </div>
            </div>

            <div class="certifications-section-container">
                <!-- Recognized national provider -->
                <div *ngIf="shop.nationalProvider" class="-oneX-row section-row">
                    <div class="-oneX-body--secondary">
                        <span class="font-semibold">
                            Recognized National Provider:
                        </span>
                        National Providers are recognized for their performance and service and have multiple locations available.
                    </div>
                </div>

                <!-- Certifications -->
                <div *ngIf="shop.certifications?.length > 0" class="-oneX-row section-row">
                    <div class="-oneX-body--secondary">
                        <span class="font-semibold">
                            Our Certifications:
                        </span>
                        {{ getCertifications() }}
                    </div>
                </div>

                <!-- Website -->
                <div class="-oneX-row section-row url-section" [style.display]="(shop?.placeDetails?.websiteUrl) ? '' : 'none'">
                    <a id="shopWebsiteLink"
                       class="-oneX-link--block -oneX-link--block--variant -oneX-link-secondary url-text font-semibold"
                       [attr.href]="shop?.placeDetails?.websiteUrl"
                       target="_blank">{{shop?.placeDetails?.website}}</a>

                    <div class="-oneX-icon-container icon-dimensions url-icon-container center-align" (click)="navigateToShopWebsite()">
                        <div class="-oneX-icon icon-dimensions" data-icon="external_link_24"> </div>
                    </div>
                </div>

                <!-- No website. Add a blank section -->
                <div *ngIf="!shop?.placeDetails?.websiteUrl" class="-oneX-row url-section">
                </div>                
            </div>
        </div>

        <div class="-oneX-row button-section-container">
            <!-- Use shop button -->
            <div *ngIf="userIsEligible$ | async"
                class="button-container">
                <button *ngIf="isSecondaryAssignment && (shop.id === this.actorId)"
                        id="useShopForClaimButton"
                        (click)="promptForUserConfirmation()"
                        (keydown.enter)="promptForUserConfirmation()"
                        class="-oneX-btn-primary -oneX-btn-medium button">
                    Keep shop for claim
                </button>
                <button *ngIf="shop.unavailable && (!isSecondaryAssignment || (shop.id !== this.actorId))"
                        id="useShopForClaimButton"
                        (click)="promptForUserConfirmation()"
                        (keydown.enter)="promptForUserConfirmation()"
                        class="-oneX-btn-primary -oneX-btn-medium button">
                    Still use this shop
                </button>
                <button *ngIf="!shop.unavailable && (!isSecondaryAssignment || (shop.id !== this.actorId))"
                        id="useShopForClaimButton"
                        (click)="promptForUserConfirmation()"
                        (keydown.enter)="promptForUserConfirmation()"
                        class="-oneX-btn-primary -oneX-btn-medium button">
                    Use shop for claim
                </button>         

            </div>
        </div>          
    </div>

</div>