import { Component, OnInit, AfterViewInit, Inject, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, NavigationStart } from '@angular/router';
import { formatDate } from '@angular/common';
import { ShopInfo } from '../model/shop-info.model';
import { ShopInfoService } from '../service/shop-info.service';
import { UrlResolverService } from '../service/url-resolver.service';
import { DataAnalyticsService } from '../service/data-analytics.service';
import { UrlParams } from '../model/url-params.model';
import { SearchResultsService } from 'app/service/search-results.service';
import { Subscription, Subject, Observable } from 'rxjs';
import { WINDOW } from '../service/window.service';
import { WindowReference } from '../model/window-reference.model';
import { CoverageInfo } from 'app/model/coverage-info.model';
import { CoverageInfoService } from 'app/service/coverage-info.service';
import { DeviceService } from '../service/device.service';
import { RentalService } from '../service/rental.service';
import { ProvidersResourceService } from 'app/service/providers-resource.service';
import { ProviderRequest } from 'app/model/provider-request.model';
import { RentalRate } from 'app/model/rental-rate.model';
import { RentalRatesInfoService } from 'app/service/rental-rates-info.service';
import { StorageService } from 'app/service/storage.service';
import { LogDetails } from 'app/model/log-details.model';
import { LogService } from 'app/service/log.service';
import { CliffLogDetails } from 'app/model/cliff-log-details.model';
import { ServiceClientsService } from 'app/service/service-clients.service';
import { Rates } from 'app/enums/rates-constants';
import { AuthService } from 'app/service/auth.service';
import { InteractionsService } from '../service/interactions.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-confirmation-page',
  templateUrl: './confirmation-page.component.html',
  styleUrls: ['./confirmation-page.component.css'],
})
export class ConfirmationPageComponent implements OnInit, AfterViewInit, OnDestroy {
  isEstimateAssist: boolean;
  externalClaimId: string;
  effectiveDate: string;
  shopInfo: ShopInfo;
  coverageInfo: CoverageInfo;
  isDeviceMobile: boolean;
  routerSubscription: Subscription;
  role: string;
  currentPage = 'ra-confirmation';
  rentalRates: RentalRate[];
  oneX: any;
  verbiage$: Observable<string>;
  rentalEligible: boolean;
  rentalCompleted: boolean;
  rentalOptOut: boolean;
  drawerStatus: string;

  ngUnsubscribe = new Subject();

  private cliffLog: CliffLogDetails;
  private logDetails: LogDetails;

  constructor(
    @Inject(WINDOW) private window: Window,
    private route: ActivatedRoute,
    private router: Router,
    private shopInfoService: ShopInfoService,
    private coverageInfoService: CoverageInfoService,
    private rentalRatesInfoService: RentalRatesInfoService,
    private providersResourceService: ProvidersResourceService,
    private urlResolverService: UrlResolverService,
    private logService: LogService,
    private dataAnalyticsService: DataAnalyticsService,
    private urlParams: UrlParams,
    private searchResultsService: SearchResultsService,
    private deviceService: DeviceService,
    private rentalService: RentalService,
    private storageService: StorageService,
    protected windowRef: WindowReference,
    private clientService: ServiceClientsService,
    private authService: AuthService,
    private interactionsService: InteractionsService,
  ) {
    this.oneX = window['oneX'];
  }

  ngOnInit() {
    this.externalClaimId = this.urlResolverService.getClaimId();
    this.effectiveDate = formatDate(new Date(), 'yyyy/MM/dd', 'en');
    this.shopInfo = this.shopInfoService.getShopInfo();
    this.coverageInfo = this.coverageInfoService.getCoverageInfo();
    this.verbiage$ = this.clientService.getComplianceVerbiage();

    this.rentalEligible = JSON.parse(
      this.storageService.getSessionStorage('isEligibleForRentalAssignments')
    );
    this.rentalOptOut = JSON.parse(
      this.storageService.getSessionStorage('isRentalOptOut')
    );
    this.rentalCompleted = JSON.parse(
      this.storageService.getSessionStorage('isRentalCompleted')
    );
    this.isEstimateAssist = JSON.parse(
      this.storageService.getSessionStorage('isEstimateAssist')
    );
    this.urlResolverService.populatePathParams(this.route);
    this.isDeviceMobile = this.deviceService.isDeviceMobile(
      this.window.innerWidth,
      this.window.innerHeight
    );
    this.getNavigationUrl();
    this.role = this.urlParams.role;

    this.oneX.Drawer.init();
    this.oneX.ExpansionPanel.init();

    this.callProvidersForRates(Rates.Hertz);
    this.callProvidersForRates(Rates.Enterprise);
  }

  initializeContextualHelp() {
    window[`oneX`][`Help`].init();
  }

  callProvidersForRates(vendor: Rates) {
    const providerRequest = new ProviderRequest();
    providerRequest.city = this.shopInfo.city;
    providerRequest.state = this.shopInfo.state;
    providerRequest.postalCode = this.shopInfo.zipCode;
    providerRequest.type = 'rental';

    if (vendor === Rates.Hertz) {
      providerRequest.vendorType = Rates.Hertz;
    } else if (vendor === Rates.Enterprise) {
      providerRequest.vendorType = Rates.Enterprise;
    }
    this.setRatePrice(providerRequest);
  }

  setRatePrice(providerRequest: ProviderRequest) {
    this.providersResourceService.getProviders(providerRequest)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: searchResults => {
          const rentalRates = searchResults[0].rentalRates;
          if (providerRequest.vendorType === Rates.Hertz) {
            this.setHertzRatePrice(rentalRates);
          } else if (providerRequest.vendorType === Rates.Enterprise) {
            this.setEnterpriseRatePrice(rentalRates);
          }
          this.cliffLog = new CliffLogDetails();
          this.cliffLog.clientOrPartyId = this.urlParams.externalClientId;
          this.cliffLog.message = 'Providers - get success';
          this.logDetails = new LogDetails();
          this.logDetails.currentPage = this.currentPage;
          this.logDetails.httpRequest = 'providers';
          this.logDetails.httpResponse = '200';
          this.logDetails.role = this.urlParams.role;
          this.logDetails.rentalVendor = providerRequest.vendorType;
          this.cliffLog.logData = this.logDetails;
          this.logService.sendLog(this.cliffLog);
        },
        error: error => {
          this.cliffLog = new CliffLogDetails();
          this.logDetails = new LogDetails();
          this.cliffLog.logLevel = 'error';
          this.cliffLog.message = 'Providers - get failure';
          this.cliffLog.clientOrPartyId = this.urlParams.externalClientId;
          this.cliffLog.httpResponse = error.status;
          this.logDetails.currentPage = this.currentPage;
          this.logDetails.role = this.urlParams.role;
          this.logDetails.httpRequest = error.url;
          this.logDetails.httpResponse = `${error.status.toString()}`;
          this.logDetails.rentalVendor = providerRequest.vendorType;
          this.cliffLog.logData = this.logDetails;
          this.logService.sendLog(this.cliffLog);
        },
      });
  }

  setHertzRatePrice(rentalRates: RentalRate[]) {
    rentalRates.forEach(rentalRate => {
      if (rentalRate.className === 'ECONOMY') {
        this.rentalRatesInfoService.setEconomyRatePriceInclusiveHertz(
          rentalRate.ratePriceInclusive
        );
      }
      if (rentalRate.className === 'INTERMEDIATE') {
        this.rentalRatesInfoService.setIntermediateRatePriceInclusiveHertz(
          rentalRate.ratePriceInclusive
        );
      }
    });
  }

  setEnterpriseRatePrice(rentalRates: RentalRate[]) {
    rentalRates.forEach(rentalRate => {
      if (rentalRate.className === 'COMPACT') {
        this.rentalRatesInfoService.setCompactRatePriceInclusiveEnterprise(
          rentalRate.ratePriceInclusive
        );
      }
      if (rentalRate.className === 'INTERMEDIATE') {
        this.rentalRatesInfoService.setIntermediateRatePriceInclusiveEnterprise(
          rentalRate.ratePriceInclusive
        );
      }
    });
  }

  ngAfterViewInit() {
    this.cliffLog = new CliffLogDetails();
    this.logDetails = new LogDetails();
    this.logDetails.currentPage = this.currentPage;

    this.authService.isUserLoggedIn().pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(loggedIn => {
      this.dataAnalyticsService.setAuthLevel(loggedIn);
    });

    if (this.searchResultsService.getSearchResultsResponse()) {
      this.logDetails.confirmationPath = this.setScreenId();
    }
    this.cliffLog.logData = this.logDetails;
    this.logService.sendLog(this.cliffLog);
  }

  getGoogleMapUrl(): string {
    const slashPlus = '\+';
    return (
      'http://www.google.com/maps?q=' +
      this.shopInfo.street +
      slashPlus +
      this.shopInfo.city +
      slashPlus +
      this.shopInfo.state +
      slashPlus +
      this.shopInfo.zipCode
    );
  }

  isUserAgent(): Boolean {
    return this.urlResolverService.getIsAgent();
  }

  isClaimant(): Boolean {
    if (this.role === 'C') {
      return true;
    } else {
      return false;
    }
  }

  redirect() {
    window.open(this.urlResolverService.getHubRedirectUrl(), '_self');
  }

  routeToRentalVehiclePreference(deliveryPreference: boolean) {
    if (
      this.storageService.getSessionStorage('rentalAssignmentsRetryCount') ===
      null
    ) {
      this.storageService.setSessionStorage('rentalAssignmentsRetryCount', '0');
    }

    this.rentalService.setDeliveryPreference(deliveryPreference);
    this.router.navigate(['rental']);
  }

  private getNavigationUrl() {
    this.routerSubscription = this.router.events.subscribe(eventValue => {
      if (eventValue instanceof NavigationStart) {
        const navigationStartValue = <NavigationStart>eventValue;
        const searchRegEx = /.*\/repairshops.*/;
        console.log('url --', navigationStartValue.url);
        if (searchRegEx.test(navigationStartValue.url)) {
          if (eventValue.restoredState) {
            console.log(
              'found a back/forward button trigger **',
              this.urlResolverService.getRAUrl()
            );
            this.windowRef.navigateTo(this.urlResolverService.getRAUrl());
          }
        }
      }
    });
  }

  rentalFAQ() {
    this.dataAnalyticsService.sendPageData('rental-coverage-faq');
    this.drawerStatus = window.getComputedStyle(
      document.querySelector('#coverageDrawer')
    ).display;
  }

  claimantRentalFAQ() {
    this.dataAnalyticsService.sendPageData('claimant-help-drawer');
    this.drawerStatus = window.getComputedStyle(
      document.querySelector('#coverageDrawer')
    ).display;
  }

  setScreenId() {
    let confirmationPath = '';
    let screenId = '';
    let eventName;

    // Determine screenId
    if (this.rentalEligible) {
      if (this.rentalOptOut && !this.isClaimant()){
        screenId = 'confirmation-rss-optout';
      } else {
        screenId = 'confirmation-rss-eligible';
      }
      eventName = 'Repair Assistant complete - rental eligible';
    } else if (this.rentalCompleted) {
      screenId = 'confirmation-rental-already-selected';
      eventName = 'Repair Assistant complete - rental already selected';
    } else {
      screenId = 'confirmation-rss-not-eligible';
      eventName = 'Repair Assistant complete - rental ineligible';
    }

    // Check if Estimate Assist
    if (this.isEstimateAssist) {
      screenId = 'estimate-assist-' + screenId;
    }

    screenId = this.dataAnalyticsService.setPageDataWithCount(screenId, this.clientService.assignmentCount);
    this.dataAnalyticsService.sendPageData(screenId);
    this.sendConfirmationLogs(screenId);
    this.interactionsService.sendInteractions(eventName, 'Auto Repair');
    confirmationPath = this.dataAnalyticsService.setPageDataWithCount(screenId, this.clientService.assignmentCount);
    this.drawerStatus = 'none';
    return confirmationPath;
  }

  sendConfirmationLogs(screenId: string) {
    this.cliffLog = new CliffLogDetails();
    this.cliffLog.message = screenId;
    this.cliffLog.messageId = 'RA_CONFIRMATION_LOGS';
    this.cliffLog.claimId = this.urlResolverService.getClaimId();
    this.cliffLog.clientOrPartyId = this.urlResolverService.getClientId();
    this.logDetails = new LogDetails(); 
    this.logDetails.currentPage = this.currentPage;
    this.logDetails.authentication = this.urlResolverService.getUrlParams().authentication;
    this.logDetails.role = this.urlResolverService.getRole();
    this.cliffLog.logData = this.logDetails;
    this.logService.sendLog(this.cliffLog);
  }

  scrollToHelpTopic(element: string) {
    setTimeout(() => {
      const elem: HTMLElement = document.getElementById(element);
      elem.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }, 1);

  }

  ngOnDestroy() {
    this.routerSubscription.unsubscribe();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
