import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { PhoneNumberPipe } from './directive/shop-card.phone-number.pipe';
import { ShopPositionPipe } from './directive/shop-position.pipe';
import { ShopMarkerPipe } from './directive/shop-marker.pipe';
import { TokenGuard } from './guards/token.guard';
import { UserIdleModule } from 'angular-user-idle';
import { A11yModule } from '@angular/cdk/a11y';
import { GoogleMapsModule } from '@angular/google-maps';
import { CommonModule } from '@angular/common';

// COMPONENTS
import { AppComponent } from './app.component';
import { SelectAShopComponent } from './select-a-shop/select-a-shop.component';
import { SearchComponent } from './search/search.component';
import { NonProgramComponent } from './non-program/non-program.component';
import { EstimateOptionsComponent } from './estimate-options/estimate-options.component';
import { ShopSelectorComponent } from './shop-selector/shop-selector.component';
import { ShopMapComponent } from './shop-map/shop-map.component';
import { ShopCardComponent } from './shop-card/shop-card.component';
import { ShopDetailsCardComponent } from './shop-details-card/shop-details-card.component';
import { ShopStarRatingComponent } from './shop-star-rating/shop-star-rating.component';
import { ConfirmationPageComponent } from './confirmation-page/confirmation-page.component';
import { RentalVehiclePreferenceComponent } from './rental-vehicle-preference/rental-vehicle-preference.component';
import { RentalConfirmationComponent } from './rental-confirmation/rental-confirmation.component';
import { AuthErrorPageComponent } from './auth-error-page/auth-error-page.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { ModalComponent } from './modal/modal.component';
import { NavigationBreadcrumbComponent } from './navigation-breadcrumb/navigation-breadcrumb.component';
import { AdspComponent } from './adsp/adsp.component';
import { OneXHeaderFullComponent } from './one-x-header-full/one-x-header-full.component';
import { OneXHeaderLimitedComponent } from './one-x-header-limited/one-x-header-limited.component';
import { OneXFooterComponent } from './one-x-footer/one-x-footer.component';
import { PrivacyBannerComponent } from './privacy-banner/privacy-banner.component';
import { PhotoEstimateLandingComponent } from './photo-estimate-landing/photo-estimate-landing.component';
import { PhotoEstimateContactComponent } from './photo-estimate-contact/photo-estimate-contact.component';
import { PhotoEstimateEditContactComponent } from './photo-estimate-edit-contact/photo-estimate-edit-contact.component';
import { PhotoEstimateConfirmationComponent } from './photo-estimate-confirmation/photo-estimate-confirmation.component';
import { PhotoEstimateErrorPageComponent } from './photo-estimate-error-page/photo-estimate-error-page.component';
import { SkeletonComponent } from './skeleton/skeleton.component';

// SERVICES
import { AdspLanguageResolverService } from './service/adsp-language-resolver.service';
import { AuthService } from './service/auth.service';
import { AuthSessionService } from './service/auth.session.service';
import { IioService } from './service/iio.service';
import { CookieService } from 'ngx-cookie-service';
import { CoverageInfoService } from './service/coverage-info.service';
import { DataAnalyticsService } from './service/data-analytics.service';
import { DeviceService } from './service/device.service';
import { FeatureFlagResolverService } from './service/feature-flag-resolver.service';
import { FooterDisplayService } from './service/footer-display.service';
import { GoogleSearchService } from './service/google-search.service';
import { LogService } from './service/log.service';
import { MaintenanceResolverService } from './service/maintenance-resolver.service';
import { ProvidersResourceService } from './service/providers-resource.service';
import { RentalRatesInfoService } from './service/rental-rates-info.service';
import { RentalService } from './service/rental.service';
import { SearchResultsService } from './service/search-results.service';
import { ShopInfoService } from './service/shop-info.service';
import { ShopSelectorManagementService } from './service/shop-selector-management.service';
import { SSOTokenService } from './service/sso.token.service';
import { UrlResolverService } from './service/url-resolver.service';
import { WINDOW_PROVIDERS } from './service/window.service';
import { JWTTokenService } from './service/jwt.token.service';
import { HelpService } from './service/help.service';

// MODELS
import { ClaimInfo } from './model/claim-info.model';
import { ClaimsResponse } from './model/claims-response.model';
import { CoverageInfo } from './model/coverage-info.model';
import { FeatureFlags } from './model/feature-flags.model';
import { Maintenance } from './model/maintenance.model';
import { ShopInfo } from './model/shop-info.model';
import { UrlParams } from './model/url-params.model';
import { VehicleInfo } from './model/vehicle-info.model';
import { WindowReference } from './model/window-reference.model';
import { ConversionService } from './service/conversion.service';
import { JWTTokenGuard } from './guards/jwt-token.guard';
import { LandingPageBComponent } from './landing-page-b/landing-page-b.component';
import { HelpCategoryComponent } from './help-category/help-category.component';
import { HelpFaqVideoComponent } from './help-faq-video/help-faq-video.component';
import { HelpStillHaveQuestionsComponent } from './help-still-have-questions/help-still-have-questions.component';
import { HelpSubcategoryComponent } from './help-subcategory/help-subcategory.component';
import { HelpFaqComponent } from './help-faq/help-faq.component';
import { HelpDrawerComponent } from './help-drawer/help-drawer.component';

@NgModule({
  declarations: [
    AppComponent,
    ModalComponent,
    SelectAShopComponent,
    SearchComponent,
    ConfirmationPageComponent,
    ShopSelectorComponent,
    ShopMapComponent,
    ShopCardComponent,
    ShopDetailsCardComponent,
    ShopStarRatingComponent,
    AdspComponent,
    RentalVehiclePreferenceComponent,
    RentalConfirmationComponent,
    PhoneNumberPipe,
    ShopPositionPipe,
    ShopMarkerPipe,
    ErrorPageComponent,
    OneXHeaderFullComponent,
    OneXHeaderLimitedComponent,
    OneXFooterComponent,
    NavigationBreadcrumbComponent,
    AuthErrorPageComponent,
    EstimateOptionsComponent,
    PrivacyBannerComponent,
    PhotoEstimateLandingComponent,
    PhotoEstimateContactComponent,
    PhotoEstimateEditContactComponent,
    PhotoEstimateConfirmationComponent,
    PhotoEstimateErrorPageComponent,
    NonProgramComponent,
    LandingPageBComponent,
    HelpCategoryComponent,
    HelpFaqVideoComponent,
    HelpStillHaveQuestionsComponent,
    HelpSubcategoryComponent,
    HelpFaqComponent,
    HelpDrawerComponent,
    SkeletonComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    NgbModule,
    UserIdleModule.forRoot({ idle: 720, timeout: 120, ping: 840 }),
    RouterModule,
    A11yModule,
    GoogleMapsModule,
    CommonModule,
    HammerModule
  ],
  providers: [
    TokenGuard,
    JWTTokenGuard,
    JWTTokenService,
    AuthService,
    ClaimsResponse,
    DataAnalyticsService,
    FeatureFlagResolverService,
    FeatureFlags,
    MaintenanceResolverService,
    Maintenance,
    ProvidersResourceService,
    SearchResultsService,
    ShopSelectorManagementService,
    ClaimInfo,
    ShopInfo,
    ShopInfoService,
    LogService,
    AuthSessionService,
    AdspLanguageResolverService,
    UrlParams,
    UrlResolverService,
    WindowReference,
    GoogleSearchService,
    PhoneNumberPipe,
    ShopPositionPipe,
    ShopMarkerPipe,
    CoverageInfo,
    CoverageInfoService,
    VehicleInfo,
    RentalRatesInfoService,
    DeviceService,
    RentalService,
    CookieService,
    IioService,
    FooterDisplayService,
    SSOTokenService,
    WINDOW_PROVIDERS,
    ConversionService,
    HelpService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
