import { AfterViewInit, Component, Inject, Input, NgZone, OnDestroy, OnInit, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from 'app/base/base.component';
import { CliffLogDetails } from 'app/model/cliff-log-details.model';
import { HelpQuestion, HelpResponse } from 'app/model/help.model';
import { LogDetails } from 'app/model/log-details.model';
import { Maintenance } from 'app/model/maintenance.model';
import { SecondaryAssignment } from 'app/model/secondary-assignment.model';
import { WindowReference } from 'app/model/window-reference.model';
import { DataAnalyticsService } from 'app/service/data-analytics.service';
import { HelpService } from 'app/service/help.service';
import { InteractionsService } from 'app/service/interactions.service';
import { LogService } from 'app/service/log.service';
import { SearchResultsService } from 'app/service/search-results.service';
import { ServiceClientsService } from 'app/service/service-clients.service';
import { ShopInfoService } from 'app/service/shop-info.service';
import { StorageService } from 'app/service/storage.service';
import { UrlResolverService } from 'app/service/url-resolver.service';
import { WINDOW } from 'app/service/window.service';
import { CookieService } from 'ngx-cookie-service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-landing-page-b',
  templateUrl: './landing-page-b.component.html',
  styleUrls: ['./landing-page-b.component.css']
})
export class LandingPageBComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {

  claimsHub: SafeUrl;
  claimsHubUrl: string;
  claimsHelp: SafeUrl;
  externalClaimId: string;
  externalClientId: string;
  participantId: string;
  role: string;
  claimId: string;
  clientId: string;
  oneX: any;
  currentPage = 'ra-landing-page-b-repair';
  lob = 'AUTO';
  isViewStatic: boolean;
  complianceStateCode: string;
  medallia: any;
  complianceVerbiage$: Observable<string>;
  currentShop$: Observable<SecondaryAssignment>;
  adspLanguage$: Observable<string>;
  maintenance$: ReplaySubject<Maintenance>;
  helpResponseObs: Observable<HelpResponse>;
  helpCategory: any = {};
  helpRASubGroups: any = {};
  helpwhatToExpectSubGroups: any = {};
  helpQuestionData: any = {};
  private ngUnsubscribe = new Subject();

  constructor(
    @Inject(WINDOW) private window: Window,
    __zone: NgZone,
    router: Router,
    private route: ActivatedRoute,
    urlResolver: UrlResolverService,
    logService: LogService,
    private dataAnalyticsService: DataAnalyticsService,
    clientService: ServiceClientsService,
    searchResultsService: SearchResultsService,
    windowRef: WindowReference,
    storageService: StorageService,
    private shopInfoService: ShopInfoService, // used in html
    private deviceService: DeviceDetectorService,
    private interactionsService: InteractionsService,
    private sanitizer: DomSanitizer,
    private urlResolverService: UrlResolverService,
    private helpService: HelpService,
    private cookieService: CookieService,
  ) {
    super(
      __zone,
      router,
      urlResolver,
      clientService,
      searchResultsService,
      windowRef,
      storageService,
      logService
    );
    this.oneX = window['oneX'];

  }

  ngOnInit(): void {
    this.cliffLog = new CliffLogDetails();
    this.logDetails = new LogDetails();
    this.urlResolver.populateQueryParams(this.route);
    this.externalClaimId = this.urlResolver.getClaimId();
    this.externalClientId = this.urlResolver.getClientId();
    this.participantId = this.urlResolver.getParticipantId();
    this.role = this.urlResolver.getRole();
    this.claimsHubUrl = this.sanitizer.sanitize(SecurityContext.URL, this.urlResolverService.buildHubRedirectUrlLandingB());
    this.claimsHub = this.sanitizer.bypassSecurityTrustUrl(this.claimsHubUrl);
    this.initiate();

    this.maintenance$ = this.clientService.callToMaintenanceService();
    this.complianceVerbiage$ = this.clientService.getComplianceVerbiage();
    this.adspLanguage$ = this.clientService.getADSPLanguage();
    this.currentShop$ = this.clientService.getCurrentShopDetail();

    this.oneX.Icons.init();
    this.oneX.ExpansionPanel.init();
    this.oneX.Drawer.init();
    this.cookieService.set('from-ra-landing-b', 'true');
    this.getHelpTopics();
  }

  private initiate() {
    this.buildDefaultLog('info', 'Started RA - B Landing Page');
    if (!this.externalClaimId) {
      this.logDetails.searchOnly = 'true';
    }
    this.logDeviceDetails();
    this.cliffLog.entryPoint = this.currentPage;
    this.cliffLog.logData = this.logDetails;
    this.logService.sendLog(this.cliffLog);

    if (this.externalClaimId && this.externalClientId) {
      this.clientService.initClientServices(
        this.externalClaimId,
        this.externalClientId,
        this.participantId
      );
    }
  }

  ngAfterViewInit() {
    if (!this.externalClaimId) {
      this.dataAnalyticsService.sendPageData('landing-page');
      this.interactionsService.sendInteractions('Repair Assistant Landing Page  - B start', 'Auto Repair');
    }

    if (this.clientService.assignmentCount) {
      this.dataAnalyticsService.sendPageData(this.dataAnalyticsService.setPageDataWithCount('landing-page', this.clientService.assignmentCount));
      this.interactionsService.sendInteractions('Repair Assistant Landing Page  - B start', 'Auto Repair');
    } else if (this.clientService.assignmentCount === 0) {
      this.dataAnalyticsService.sendPageData('landing-page');
      this.interactionsService.sendInteractions('Repair Assistant Landing Page - B start', 'Auto Repair');
    } else {
      this.clientService.getAssignmentCount().pipe(takeUntil(this.ngUnsubscribe)).subscribe({
        next: res => {
          this.dataAnalyticsService.sendPageData(this.dataAnalyticsService.setPageDataWithCount('landing-page', res));
          this.interactionsService.sendInteractions('Repair Assistant Landing Page - B start', 'Auto Repair');
        },
      });
    }
    // medallia survey support
    this.clientService.getClaimNumber().subscribe({
      next: response => {
        this.dataAnalyticsService.setClaimNumber(response);
      },
    });
    setTimeout(() => {
      if ((this.medallia = window['KAMPYLE_ONSITE_SDK'])) {
        this.medallia.updatePageView();
      } else {
        this.buildDefaultLog(
          'info',
          'medallia script failed to load within time limit'
        );
        this.logEvent();
      }
    }, 5000);
  }

  initializeContextualHelp() {
    window[`oneX`][`Help`].init();
  }

  private logDeviceDetails() {
    this.logDetails.devicePlatform = this.getDevicePlatform();
    this.logDetails.browser = this.deviceService.browser;
    this.logDetails.browserVersion = this.deviceService.browser_version;
    this.logDetails.os = this.deviceService.os;
    this.logDetails.osVersion = this.deviceService.os_version;
  }

  private getDevicePlatform() {
    if (this.deviceService.isDesktop) {
      this.interactionsService.setDeviceTypeName('DESK');
      return 'desktop';
    } else if (this.deviceService.isTablet) {
      this.interactionsService.setDeviceTypeName('TABL');
      return 'tablet';
    } else {
      this.interactionsService.setDeviceTypeName('MOBL');
      return 'mobile';
    }
  }

  getHelpTopics() {
    this.helpResponseObs = this.helpService.getHelpData(this.externalClaimId, this.role.toUpperCase(), this.lob, this.externalClientId);
    this.helpResponseObs.subscribe((response) => {
      if(response.categories != undefined){
        this.helpCategory = response.categories.filter((x) => x.name === "ESTIMATE" || x.name === "APPRAISAL")
        this.helpRASubGroups = this.helpCategory[0].subGroups.filter((x) => x.name === "IN-NETWORK_SHOPS" || x.name === "REPAIR")
        this.helpwhatToExpectSubGroups = this.helpCategory[0].subGroups.filter((x) => x.name === "WHAT_TO_EXPECT");
      }
    });

  }

  scrollToHelpTopic(element: string) {
    setTimeout(() => {
      const elem: HTMLElement = document.getElementById(element);
      elem.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }, 1);
  }

  mainTop() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }


  scrollToAdsp() {
    setTimeout(() => {
      const elem: HTMLElement = document.getElementById('adsp');
      elem.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }, 1);
  }

  scrollToSSS() {
    setTimeout(() => {
      const elem: HTMLElement = document.getElementById('select-service-section');
      elem.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }, 1);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
