import { Component, OnInit, Inject, OnDestroy, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';

// SERVICES
import { DeviceService } from '../service/device.service';
import { LogService } from '../service/log.service';
import { ServiceClientsService } from '../service/service-clients.service';
import { StorageService } from '../service/storage.service';
import { UrlResolverService } from '../service/url-resolver.service';
import { WINDOW } from '../service/window.service';

// MODELS
import { CliffLogDetails } from '../model/cliff-log-details.model';
import { LogDetails } from '../model/log-details.model';
import { DataAnalyticsService } from 'app/service/data-analytics.service';

import { SubSink } from 'subsink';

@Component({
  selector: 'app-photo-estimate-landing',
  templateUrl: './photo-estimate-landing.component.html',
  styleUrls: ['./photo-estimate-landing.component.css']
})
export class PhotoEstimateLandingComponent implements OnInit, AfterViewInit, OnDestroy {
  contentLoaded = false;
  currentPage: string;
  isDeviceMobile: boolean;

  cliffLog: CliffLogDetails;
  logDetails: LogDetails;

  externalClaimId: string;
  externalClientId: string;
  role: string;

  isNonProgram: boolean;

  complianceVerbiage$: Observable<string>;
  private subs = new SubSink();

  constructor(
    @Inject (WINDOW) private window: Window,
    private deviceService: DeviceService,
    private router: Router,
    private route: ActivatedRoute,
    protected clientService: ServiceClientsService,
    private logService: LogService,
    private storageService: StorageService,
    private dataAnalyticsService: DataAnalyticsService,
    private urlResolverService: UrlResolverService
  ) { }

  ngOnInit() {
    this.currentPage = 'photo-estimate-landing';

    this.isDeviceMobile = this.deviceService.isDeviceMobile(this.window.innerWidth, this.window.innerHeight);

    this.complianceVerbiage$ = this.clientService.getComplianceVerbiage();
    
    // Determines if customer is coming from RA or external source
    const searchedResultFromSessionStorage = this.storageService.getSessionStorage('searchResult');

    let initServices: boolean;

    if (searchedResultFromSessionStorage) {
      // Customer comes from within RA
      this.isNonProgram = true;

      this.externalClaimId = this.urlResolverService.getClaimId();
      this.externalClientId = this.urlResolverService.getClientId();
      this.role = this.urlResolverService.getRole();

      if (this.externalClaimId && this.externalClientId) {
        initServices = false;
      } else {
        initServices = true;
      }
    } else {
      // Customer comes from outside RA
      this.isNonProgram = false;
      initServices = true
    }

    if (initServices === true) {
      this.urlResolverService.populatePathParams(this.route);
      this.externalClaimId = this.urlResolverService.getClaimId();
      this.externalClientId = this.urlResolverService.getClientId();
      this.role = this.urlResolverService.getRole();

      if (this.externalClaimId && this.externalClientId) {
        this.clientService.initClientServices(this.externalClaimId, this.externalClientId);
      } 
    }

    this.subs.sink = this.clientService.loaded$.subscribe((res) => {
      this.contentLoaded = res;
    })

    this.buildDefaultLog('RA_PE_LANDING', 'info', 'Photo Estimate Landing Page');
    this.sendLog();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  goToShopSearch() {
    this.router.navigate(['shopsearch'], {queryParams: this.urlResolverService.getQueryParamsForUrl()});
  }

  goToPhotoEstimateContact() {
    this.router.navigate([
      'pecontact/claim/' + this.externalClaimId + '/role/' + this.role + '/client/' + this.externalClientId
    ]);
  }

  buildDefaultLog(messageId: string, logLevel: string, message: string) {
    this.cliffLog = new CliffLogDetails();
    this.logDetails = new LogDetails();
    this.cliffLog.messageId = messageId;
    this.cliffLog.logLevel = logLevel;
    this.cliffLog.message = message;
    this.cliffLog.claimId = this.externalClaimId;
    this.cliffLog.clientOrPartyId = this.externalClientId;
    this.logDetails.role = this.role;
    this.logDetails.currentPage = this.currentPage;
    this.logDetails.authentication = this.urlResolverService.getUrlParams().authentication;
  }

  sendLog() {
    this.cliffLog.logData = this.logDetails;
    this.logService.sendLog(this.cliffLog);
  }

  ngAfterViewInit(): void {
    this.dataAnalyticsService.sendPageData('photo-estimate-landing');
  }
}
