import { Component, Input } from '@angular/core';
import { SearchResult } from '../model/search-result.model';
import { ClaimInfo } from '../model/claim-info.model';
import { DataAnalyticsService } from '../service/data-analytics.service';
import { PlacesDetails } from '../model/places-details.model';
import { LogDetails } from 'app/model/log-details.model';
import { LogService } from 'app/service/log.service';
import { CliffLogDetails } from 'app/model/cliff-log-details.model';

@Component({
  selector: 'app-shop-star-rating',
  templateUrl: './shop-star-rating.component.html',
  styleUrls: ['./shop-star-rating.component.css'],
})
export class ShopStarRatingComponent {
  @Input() index: number;
  @Input() type: string;
  @Input() location: PlacesDetails;
  @Input() isSidebarVisabile: boolean;
  @Input() areReviewsClickable: boolean = true;


  private cliffLog = new CliffLogDetails();
  private logDetails = new LogDetails();

  constructor(private dataAnalyticsService: DataAnalyticsService,
    private logService: LogService) {}


  onReviewsClick() {

    this.cliffLog.message = 'Google Review';
    this.logDetails.googleShopReview = this.location.placeName;
    this.cliffLog.logData = this.logDetails;
    this.logService.sendLog(this.cliffLog);
  }

  getFormattedRating(rating: number): string {
    try {
      if (Number.isInteger(rating)) {
        return rating === undefined ? '' : ((rating / 100) * 5).toFixed(1);
      } else {
        return '';
      }
    } catch (error) {}
  }

  getRating() {
    try {
      return this.location.rating;
    } catch (error) {}
    return null;
  }

  getPlaceId() {
    try {
      return this.location.placeId;
    } catch (error) {}
    return null;
  }

  getUserRatings() {
    try {
      return this.location.user_ratings_total;
    } catch (error) {}
    return null;
  }
}
